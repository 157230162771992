import * as OrderConstants from './constants';
import keys from 'lodash/keys';

export function initState(state) {
  return {
    type: OrderConstants.ADD_PACKAGES_INIT_STATE,
    state
  };
}

export function initCreatePageState() {
  return {
    type: OrderConstants.NEW_ORDER_INIT_STATE
  };
}


export function loadOrderPackages(orderId) {
  return {
    type: OrderConstants.NEW_ORDER_LOAD_OERDER_PACKAGES,
    orderId
  };
}

export function changeWizardStep(step) {
  return {
    type: OrderConstants.NEW_ORDER_WIZARD_STEP,
    step
  };
}
export function submitOrder(order) {
  return {
    type: OrderConstants.NEW_ORDER_SUBMIT,
    order
  };
}

export function submitOrderSuccess(order) {
  return {
    type: OrderConstants.NEW_ORDER_SUBMIT_SUCCESS,
    order
  };
}

export function submitOrderFailed(order) {
  return {
    type: OrderConstants.NEW_ORDER_SUBMIT_FAILED,
    order
  };
}

export function loadQuoteDetails(quoteId) {
  return {
    type: OrderConstants.QUOTE_DETAILS_LOAD_QUOTE,
    quoteId
  }
}

export function loadQuoteDetailsSuccess(quote) {
  return {
    type: OrderConstants.QUOTE_DETAILS_LOAD_QUOTE_SUCCESS,
    quote
  }
}

export function loadOrderIdentity(orderId) {
  return {
    type: OrderConstants.NEW_ORDER_LOAD_IDENTITY,
    orderId
  }
}

export function saveGeometries(geometries) {
  return {
    type: OrderConstants.NEW_GEOMETRIES,
    geometries
  }
}

export function clearGeometries() {
  return {
    type: OrderConstants.CLEAR_GEOMETRIES
  }
}

/**
 * Starts delete property-plan.png file saga
 * @param {*} filePath 
 * @param {*} fileName 
 * @param {*} uploadChannel 
 * @returns 
 */
export function deleteMap(filePath, fileName, uploadChannel) {
  return {
    type: OrderConstants.DELETE_MAP,
    filePath, fileName, uploadChannel
  }
}

export function saveUserMapIteration(mapSaveDiscardAction) {
  return {
    type: OrderConstants.MAP_USER_ACTION,
    mapSaveDiscardAction
  }
}

export function addValidationMessage(key, message) {
  return {
    type: OrderConstants.ADD_VALIDATION_MESSAGE,
    message,
    key
  }
}
export function removeValidationMessage(key) {
  return {
    type: OrderConstants.REMOVE_VALIDATION_MESSAGE,
    key
  }
}

export function saveIsBusy(isBusyState) {
  return {
    type: OrderConstants.IS_BUSY,
    isBusyState
  }
}

export function loadExistingOrderDetails(orderId) {
  return {
    type: OrderConstants.LOAD_EXISTING_ORDER_FOR_COPY,
    orderId
  }
}

export function saveQuote(payload) {
  return {
    type: OrderConstants.NEW_ORDER_SAVE_QUOTE,
    payload
  };
}

export function saveQuoteSuccess(quoteId) {
  return {
    type: OrderConstants.NEW_ORDER_SAVE_QUOTE_SUCCESS,
    quoteId
  };
}
export function submitQuoteFailed() {
  return {
    type: OrderConstants.NEW_ORDER_SAVE_QUOTE_ERROR
  };
}

export function selectAddress(address, validation) {
  return {
    type: OrderConstants.NEW_ORDER_ADDRESS_SELECTED,
    address,
    validation
  };
}

/*
* used by edit address functionality
*/
export function changeAddressValidation(validation) {
  return {
    type: OrderConstants.NEW_ORDER_ADDRESS_VALIDATION_CHANGED,
    validation
  };
}
export function setAddressSaveTooltip(value) {
  return {
    type: OrderConstants.SHOW_ADDRESS_SAVE_TOOLTIP,
    value
  };
}

export function changeProductCategoryTab(tabName) {
  return {
    type: OrderConstants.CHANGE_ACTIVE_PRODUCT_CATEGORY_TAB,
    tabName
  };
}

export function changeProductClassificationTab(tabName) {
  return {
    type: OrderConstants.CHANGE_ACTIVE_PRODUCT_CLASSIFICATION_TAB,
    tabName
  };
}

export function changeLALetterTab(tabName) {
  return {
    type: OrderConstants.CHANGE_ACTIVE_LA_LETTER_TAB,
    tabName
  };
}

export function loadPackagesInfo() {
  return {
    type: OrderConstants.LOAD_PACKAGE_SALE_INFO
  };
}

export function selectPackage(packageId, value, gridName, termsRequired) {
  return {
    type: OrderConstants.SELECT_PACKAGE,
    packageId,
    value,
    gridName,
    termsRequired
  };
}

export function selectTmIntegrationPackage(packageId, productId, additional, value) {
  return {
    type: OrderConstants.SELECT_PACKAGE_CON29_OPTIONAL_ENQUIRY,
    packageId, productId, additional, value
  }
}

export function setAdditionalTextToProduct(packageId, productId, text) {
  return {
    type: OrderConstants.SET_PACKAGE_PRODUCT_ADDITIONAL_TEXT,
    packageId, productId, text
  }
}
export function setAgreeCheckBoxToProduct(packageId, productId, checkbox) {
  return {
    type: OrderConstants.SET_PACKAGE_PRODUCT_AGREE_CHECKBOX,
    packageId, productId, checkbox
  }
}
//
// fire event for showing additional info
export function showAdditionalInfoPackage(packageId, gridName) {
  return {
    type: OrderConstants.SHOW_PACKAGE_ADDITIONAL_INFO,
    packageId,
    gridName
  };
}

//
// fire event for showing additional info
export function hideAdditionalInfoPackage(packageId, gridName) {
  return {
    type: OrderConstants.HIDE_PACKAGE_ADDITIONAL_INFO,
    packageId,
    gridName
  };
}


//
// core discount package.
// use it for add and delete
export function selectDiscountPackage(packageId, value) {
  return {
    type: OrderConstants.SELECT_DISCOUNT_PACKAGE,
    packageId,
    value
  };
}

export function openDiscountPackagesModal(packageId) {
  return {
    type: OrderConstants.NEW_ORDER_DISCOUNT_PACKAGE_OPEN,
    packageId
  };
}
export function loadDiscountPackagesSuccess(packages) {
  return {
    type: OrderConstants.NEW_ORDER_DISCOUNT_PACKAGE_LOADED,
    packages
  };
}
export function closeDiscountPackagesModal() {
  return {
    type: OrderConstants.NEW_ORDER_DISCOUNT_PACKAGE_CLOSE
  };
}

//
// discount packages from dialog
export function addSelectedDiscountPackages(packages) {
  return {
    type: OrderConstants.NEW_ORDER_DISCOUNT_PACKAGES_ADD_SELECTED,
    packages
  }
}

export function toggleFavorite(packageId, value) {
  return {
    type: OrderConstants.TOGGLE_FAVORITE_PACKAGE,
    packageId,
    value
  };
}
export function toggleAllFavorite(value, gridName, selectedPackages, selectedPackagesInOrder) {
  return {
    type: OrderConstants.SELECT_ALL_FAVORITE_PACKAGE,
    value,
    gridName,
    selectedPackages,
    selectedPackagesInOrder
  };
}
export function packagesSaleInfoLoaded(packagesInfo) {
  var madePackages = groupPackages(packagesInfo);
  return {
    type: OrderConstants.LOAD_PACKAGE_SALE_INFO_SUCCESS,
    packages: packagesInfo.packages,
    madePackages: madePackages.packageDictionary,
    favoritePackages: madePackages.favorite,
    vatRate: packagesInfo.vatRate
  };
}

export function packagesSaleInfoLoadingError(error) {
  return {
    type: OrderConstants.LOAD_PACKAGE_SALE_INFO_ERROR,
    error,
  };
}

export function selectContactPersonAction(id) {
  return {
    type: OrderConstants.NEW_ORDER_SELECT_CONTACT_PERSON,
    id,
  };
}

export function selectCustomerReferenceAction(custRef) {
  return {
    type: OrderConstants.NEW_ORDER_SELECT_CUSTOMER_REFERENCE,
    custRef,
  };
}

export function selectDeveloperNameAction(name) {
  return {
    type: OrderConstants.NEW_ORDER_SELECT_DEVELOPER_NAME,
    name,
  };
}

export function selectSiteNameAction(name) {
  return {
    type: OrderConstants.NEW_ORDER_SELECT_SITE_NAME,
    name,
  };
}

export function selectAdditionalInfoAction(info) {
  return {
    type: OrderConstants.NEW_ORDER_SELECT_ADDITIONAL_INFO,
    info,
  };
}
export function showBasketOnMobile() {
  return {
    type: OrderConstants.NEW_ORDER_SHOW_BASKET_ON_MOBILE
  };
}

export function hideBasketOnMobile() {
  return {
    type: OrderConstants.NEW_ORDER_HIDE_BASKET_ON_MOBILE
  };
}
export function toggleMapSaveButton(value) {
  return {
    type: OrderConstants.NEW_ORDER_TOGGLE_MAP_SAVE_BUTTON,
    value
  };
}
export function getAlertsOnSiteGeometryChange(geometry) {
  return {
    type: OrderConstants.NEW_ORDER_SITE_GEOMETRY_CHANGED,
    geometry
  };
}

export function setAlertsLoading() {
  return {
    type: OrderConstants.NEW_ORDER_SET_ALERTS_LOADING
  };
}

export function setLocalAuthorityRecommendedPackages(packageIds) {
  return {
    type: OrderConstants.SET_RECOMMENDED_LOCAL_AUTHORITY_PACKAGES,
    packageIds
  };
}

export function setAlertsRecommendedPackages(packages) {
  return {
    type: OrderConstants.SET_RECOMMENDED_ALERTS_PACKAGES,
    packages
  };
}

//
// the state has to contain address. It is just after copy/load order
export function getAlertsFromLandmark() {
  return {
    type: OrderConstants.NEW_ORDER_GET_ALERTS
  };
}

export function setAlertsPackagesForOrder(packages) {
  return {
    type: OrderConstants.SET_ALERTS_PACKAGES_FOR_ORDER,
    packages
  };
}

export function resetIsLoadingForAlerts() {
  return {
    type: OrderConstants.RESET_LOADING_ALERTS
  };
}


export function setPackageAdditionalInfoData(packageId, gridName, extraInfo) {
  return {
    type: OrderConstants.PACKAGE_ADDITIONAL_INFO_SUCCESS,
    packageId,
    gridName,
    extraInfo
  };
}

export function setPackageAdditionalInfoDataError(packageId, gridName, errorMsg) {
  return {
    type: OrderConstants.PACKAGE_ADDITIONAL_INFO_ERROR,
    packageId, gridName, errorMsg
  };
}


export function addPackagesToOrder(orderId, packages) {
  return {
    type: OrderConstants.ADD_PACKAGES_REQUEST,
    payload: { orderId, packages }
  };
}

export function addPackagesToOrderSuccess() {
  return {
    type: OrderConstants.ADD_PACKAGES_SUCCESS
  };
}

export function addPackagesToOrderFailed() {
  return {
    type: OrderConstants.ADD_PACKAGES_ERROR
  };
}
export function showAddressWarning(packageId, gridName) {
  return {
    type: OrderConstants.SHOW_PACKAGE_ADDITIONAL_INFO_ADDRESS_WARNING,
    packageId, gridName
  };
}
export function setFavoritePackages(favoritePackages) {
  return {
    type: OrderConstants.SET_FAVORITE_PACKAGES_AFTER_ERROR,
    favoritePackages
  };
}

export function setAddressForAditionalProducts(address) {
  return {
    type: OrderConstants.SET_ADDRESS_FOR_MORE_PRODUCTS,
    address
  };
}
export function closeSwitchProductDlg(packageId, sectionIndex) {
  return {
    type: OrderConstants.CLOSE_SWITCH_PRODUCT_DIALOG,
    packageId,
    sectionIndex
  };
}
export function switchToSuggestedPackage(oldPackageId, newPackageId, sectionIndex = 0) {
  return {
    type: OrderConstants.SWITCH_TO_SUGGESTED_PACKAGE,
    oldPackageId,
    newPackageId,
    sectionIndex
  };
}


function groupPackages(packagesInfo) {
  //Extract the Residential Packages
  /*var packageDictionary={
    comCon29:[],
    con29:[],
    authority:{
      com:{},
      res:{},
      onesearch:[],
      laSearches:{}
    },
    land:[],
    env:[],
    mining:[],
    spe:[],
    ins:[],
    chancel:[],
    utility: [],
    dis:[]
  };*/

  var packageDictionary = {
    res: {
      authority: {}
    },
    com: {
      authority: {}
    },
    dis: [],
    other: []
  };

  var packages = packagesInfo.packages;
  var favorite = [];
  //var residentialPackages = packages.filter(function (p) { return p.category === "Residential" && p.classification !== "Discount Packages"; });

  keys(packages).forEach(function (key) {
    var p = packages[key];
    if (p.classification === "Discount Packages") {
      packageDictionary.dis.push(p);
      return;
    }

    if (p.category === 'Residential' || p.category === 'ResidentialPopular') {
      var classification = getPackageClassification(p.classification);
      classification = classification == undefined ? 'con29' : classification;
      if (classification === 'authority') {
        var alpha = 'A';
        if (/CON29/i.test(p.name) && !/\*/i.test(p.name)) {
          alpha = 'la-' + p.name.toUpperCase().split("CON29")[1].trim().substring(0, 1).toLowerCase();
        } else if (/\*/i.test(p.name) && /CON29 O/i.test(p.name)) {
          alpha = "optional";
        } else if (/ONESEARCH/i.test(p.name)) {
          alpha = "optional"; //"one-search";
        } else if (/\*/i.test(p.name) && /CON29 R/i.test(p.name)) {
          alpha = "optional";// "other-la";
        }
        if (packageDictionary.res.authority[alpha] == undefined) {
          packageDictionary.res.authority[alpha] = [];
        }

        packageDictionary.res.authority[alpha].push(p);
        return;
      }
      else {
        if (packageDictionary.res[classification] == undefined) {
          packageDictionary.res[classification] = [];
        }
        packageDictionary.res[classification].push(p);
        return;
      }
    }

    if (p.category === 'Commercial' || p.category === 'CommercialPopular') {
      var classification = getPackageClassification(p.classification);
      classification = classification == undefined ? 'comCon29' : classification;
      if (classification === 'authority') {
        var alpha = 'A';
        if (/CON29/i.test(p.name) && !/\*/i.test(p.name)) {
          alpha = 'la-' + p.name.toUpperCase().split("CON29")[1].trim().substring(0, 1).toLowerCase();
        } else if (/\*/i.test(p.name) && /CON29 O/i.test(p.name)) {
          alpha = "optional";
        } else if (/ONESEARCH/i.test(p.name)) {
          alpha = "optional"; //"one-search";
        } else if (/\*/i.test(p.name) && /CON29 R/i.test(p.name)) {
          alpha = "optional"; //"other-la";
        }
        if (packageDictionary.com.authority[alpha] == undefined) {
          packageDictionary.com.authority[alpha] = [];
        }

        packageDictionary.com.authority[alpha].push(p);
        return;
      }
      else {
        if (packageDictionary.com[classification] == undefined) {
          packageDictionary.com[classification] = [];
        }
        packageDictionary.com[classification].push(p);
        return;
      }
    }

    packageDictionary.other.push(p);
  }, this);

  packagesInfo.favoritePackages.forEach((info) => {
    var p = packagesInfo.packages[info.packageId || info.id];
    favorite.push(p);
  });

  // sort LA packages by name asc.
  /*for (var i = 10; i < 36; i++) {
      var authLetter = "la-" + i.toString(36);
      var resAuth = packageDictionary.res.authority;
      var comAuth = packageDictionary.com.authority;
      var currentArray = resAuth[authLetter];
      if(currentArray != undefined){
          currentArray.sort(sortByName);
      }

      currentArray = comAuth[authLetter];
      if(currentArray != undefined){
          currentArray.sort(sortByName);
      }
  }*/

  // sort packages by display order. Only LA-* packages are by name
  keys(packageDictionary).forEach(function (key) {
    if (key == 'dis') {
      var classification = packageDictionary[key];
      classification.sort(sortByDisplayOrder);
      return;
    }

    if(key == 'other'){
      return; // dont sort other because they are not visible
    }

    var category = packageDictionary[key];
    keys(category).forEach(function (catKey) {
      var classification = category[catKey];
      if (catKey == 'authority') {
        keys(classification).forEach(function (authKey) {
          var currentArray = classification[authKey];
          if (currentArray != undefined) {
            currentArray.sort(sortByName);
          }
        });
      } else {
        classification.sort(sortByDisplayOrder);
      }
    })
  });

  return { packageDictionary, favorite };
}

function sortByName(a, b) {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

function sortByDisplayOrder(a, b) {
  if (a.displayOrder < b.displayOrder) {
    return -1;
  }
  if (a.displayOrder > b.displayOrder) {
    return 1;
  }

  // names must be equal
  return 0;
}

function getPackageClassification(classification) {
  switch (classification) {
    case "CON29DW drainage and water searches":
      return 'con29';
    case "Commercial Drainage and Water Searches":
      return 'comCon29';
    case 'Local Authority Searches':
      return 'authority';
    case 'Regulated LA Searches':
      return 'land';
    case 'Environmental Searches':
      return 'env';
    case 'Mining Searches':
      return 'mining';
    case 'Specialist Searches':
      return 'spe';
    case 'Insurance':
      return 'ins';
    case 'Chancel Repair Liability':
      return 'chancel';
    case 'Utility Searches':
      return 'utility';

    default:
      return classification;

  }
}
