import React from 'react';
import { Link } from 'react-router-dom';

export default class PageTitle extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  render(){
    return (
        <div className="page-title">
            <div className="content-wrapper">
                <h1>Overview</h1>
                {this.props.orderCteationType 
                    ? <Link to="/create-order" className="button button-small button-green align-left hide-mobile">Create New Order</Link>
                    : <Link to="/create-order-wizard" className="button button-small button-green align-left hide-mobile">Create New Order - Wizard</Link>
                }
            </div>
        </div>);
   }
}
