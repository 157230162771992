import React from 'react';
import { createStructuredSelector } from 'reselect';
import ReactGA from 'react-ga4';
import { selectHowIsAddressEntered, selectAddress, selectIsMapLocked, selectShowAlertForSave } from './selectors';
import { changeWizardStep, toggleMapSaveButton, saveIsBusy } from './actions';
import { connect } from 'react-redux';
import NewOrderAddress from './newOrderAddress';
import NewOrderAdditionalInfo from './NewOrderAdditionalInfo';
import Form from '../../components/Form';
import { selectMap } from '../App/selectors';
import { MapIframe } from '../../components/Map/map-iframe';
import { uploadRequest } from '../../components/UploadFile/actions';
import { saveGeometries } from './actions';

class WizardStep1 extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.send({ hitType: "pageview", page: "/create-order-wizard" });
        ReactGA.event({
            category: 'Order wizard',
            action: 'start order wizard',
            label: 'step 1 for user: ' + userName
        });
    }

    handleNextStep() {
        if (this.props.howIsAddressEntered == 'none') {
            return;
        }
        this.props.changeWizardStep(2);
    }

    handelGeometriesPictureSave = (data) => {
        if (data.polygonImage !== undefined) {
            this.props.uploadRequest(data.polygonImage, -1, 'order', this.uploadChannel, { isNotDownloadable: true });
        }

        // remove file from data so we will save it as string in Pandora db
        var meta = {
            geometries: data.geometries,
            geometriesArea: data.geometriesArea,
            grids: data.grids,
            gridsMarker: data.gridsMarker
        };
        this.props.saveGeometries(meta);
    }

    render() {
        return (
            <Form className="content-wrapper">
                <NewOrderAddress />

                {/* Shows map only if it is allowed. */}
                {(this.props.showMapFeature === "true") ?
                    <MapIframe address={this.props.selectedAddress}
                        pictureGeometriesSave={this.handelGeometriesPictureSave}
                        isMapLocked={this.props.isMapLocked}
                        showAlertForSave={this.props.showAlertForSave}
                        toggleMapSaveButton={this.props.toggleMapSaveButton}
                        saveIsBusy={this.props.saveIsBusy} />
                    : null}
                {/*Extra info*/}
                <NewOrderAdditionalInfo />
                <div className="order-wizard-actions">
                    <button style={{ width: 200 }} type='button' onClick={() => { this.handleNextStep() }} className="button button-regular button-blue" >Next: Select Products</button>
                    {/*<a onClick={()=>{this.handleNextStep()}} className="button button-regular button-blue">Next: Select Products</a>*/}
                </div>
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    howIsAddressEntered: selectHowIsAddressEntered(),
    showMapFeature: selectMap(),
    selectedAddress: selectAddress(),
    isMapLocked: selectIsMapLocked(),
    showAlertForSave: selectShowAlertForSave()
})
const mapDispatchToProps = {
    changeWizardStep: changeWizardStep,
    uploadRequest: uploadRequest,
    saveGeometries: saveGeometries,
    toggleMapSaveButton: toggleMapSaveButton,
    saveIsBusy: saveIsBusy
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(WizardStep1);
