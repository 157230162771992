import React from 'react';
import ReactGA from 'react-ga4';

export default class WizardAsideAddress extends React.PureComponent {
  componentDidMount() {
      ReactGA.send({ hitType: "pageview", page: "/create-order-wizard" });
  }
    render() {

        return (
            <div className="details-aside-content">
                <div className="details-aside-address">
                    <ul className="vcard">
                        <li>
                            <span className="label">Flat/Unit Number</span>
                            <span className="value">{ this.props.address.subbuilding }</span>
                        </li>
                        <li>
                            <span className="label">House Number</span>
                            <span className="value">{ this.props.address.buildingNumber }</span>
                        </li>
                        <li>
                            <span className="label">House Name</span>
                            <span className="value">{ this.props.address.buildingName }</span>
                        </li>
                        <li>
                            <span className="label">Street</span>
                            <span className="value">{ this.props.address.thoroughfare }</span>
                        </li>
                        <li>
                            <span className="label">Locality</span>
                            <span className="value">{ this.props.address.department }</span>
                        </li>
                        <li>
                            <span className="label">City</span>
                            <span className="value">{ this.props.address.town }</span>
                        </li>
                        <li>
                            <span className="label">County</span>
                            <span className="value">{ this.props.address.county }</span>
                        </li>
                        <li>
                            <span className="label">Postcode</span>
                            <span className="value">{ this.props.address.postcode }</span>
                        </li>
                    </ul>
                    {this.props.howIsAddressEntered == 'gbg' ?
                            <span className="status-label completed">Validated</span>
                        :<span className="status-label warning">Manual</span>
                    }
                </div>
            </div>
        );
    }
}
