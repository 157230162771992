import * as Actions from './constants';
import { LOAD_CUSTOMER_CONTACTS_SUCCESS } from '../App/constants';
import { createUploadReducer } from '../../components/UploadFile/reducer';
import { combineReducers } from 'redux';
import keys from 'lodash/keys';
import find from 'lodash/find';
import { UploadActions } from '../../components/UploadFile/actions';
import { original, current, produce, createDraft } from "immer";

function getDatasourceNameByGridName(gridName) {
    if (gridName == "grid-authority-other") {
        return 'laAuthorityOtherDatasource';
    }

    if (gridName == "gridResFavorite") {
        return 'favoriteDatasource';
    }

    if (gridName == "gridResProducts") {
        return 'productDatasource';
    }

    if (gridName == "gridRecommended") {
        return 'recommendedDatasource';
    }
}

/*
 packagesToAdd: list of id only
 packagesToDelete: list of id only
 */
function setRecommended(recommendedList, packagesToAdd, packagesToDelete, allPackages) {
    if (packagesToDelete) {
        packagesToDelete.forEach(item => {
            if (item == undefined) {
                return false;
            }

            var index = recommendedList.findIndex(itemInList => {
                return itemInList.id == item;
            });

            if (index > -1) {
                recommendedList.splice(index, 1);
            }
        });
    }

    if (packagesToAdd) {
        packagesToAdd.forEach(item => {
            var pack = allPackages[item];
            if (pack != undefined) {
                recommendedList.splice(0, 0, pack);
            }
        })
    }

    return recommendedList;
}

/***
 * Adds other water companies packages and coal package to product list for tab and classification
 */
function getProductDatasource(packages, draft, allPackages) {
    if (packages == undefined || keys(draft.groupByPackages).length == 0) {
        return draft.groupByPackages;
    }

    var groupByPackagesJs = draft.groupByPackages;


    if (packages.coals) {
        packages.coals
            .forEach(item => {
                var groupArray = groupByPackagesJs[item.category][item.classification];
                var searchForDuplica = groupArray.filter(a => a.id == item.packageId);
                if (searchForDuplica.length == 0) {
                    // add package only if it is not already there
                    var p = allPackages[item.packageId];
                    groupByPackagesJs[item.category][item.classification].push(p);
                }
            });
    }

    if (packages.waterPackagesOtherCompanies) {
        packages.waterPackagesOtherCompanies
            .forEach(item => {
                var groupArray = groupByPackagesJs[item.category][item.classification];
                var searchForDuplica = groupArray.filter(a => { if (a == undefined) return false; return a.id == item.packageId });
                if (searchForDuplica.length == 0) {
                    // add package only if it is not already there
                    var p = allPackages[item.packageId];
                    groupByPackagesJs[item.category][item.classification].push(p);
                }
            });
    }

    //return Map(fromJS(groupByPackagesJs));
    return groupByPackagesJs;
}

function getFavoritePackagesByType(list, type) {
    if (type == 'res') {
        var f = list.filter(p => {
            return p.code.startsWith("COM_") == false;
        });

        // insert to 0 index
        f.splice(0, 0, { rowTemplateKey: 'selectAllFavouriteKey', data: { selectAllFavouriteKey: true } });
        return f;
    }

    return list.filter(p => p.code.startsWith("COM_"));
}

function replaceLAInFavorite(recommendedList, favorites, categoryTab) {
    // replace LLC package with real one in favourite grid
    var newFavorites = [];
    var check = categoryTab === 'com' ? 'COM_LLC1_CON29' : 'RES_LLC1_CON29';
    var alreadyAdded = false;
    if (recommendedList.length !== 0) {
        recommendedList.forEach((llcPackForThatAddress) => {
            if (llcPackForThatAddress.code.indexOf(check) > -1) {
                favorites.forEach((favoritePackage, i) => {
                    if (llcPackForThatAddress.code.indexOf(favoritePackage.code) > -1 && alreadyAdded == false) {
                        // replace general LLC with the specific one
                        // or if specific LLC is already exists in favorite then add it to modified list
                        // we dont care which one is first in favorite list
                        newFavorites.splice(i, 1, llcPackForThatAddress);
                        alreadyAdded = true;
                    }
                    else if (favoritePackage.code !== undefined && favoritePackage.code.indexOf(check) == -1) {
                        newFavorites.splice(i, 1, favoritePackage);
                    }
                })
            }
        });

        return newFavorites;
    }

    return favorites;
}

function getSelectedWaterPackageId(draft) {
    let packageId;
    Object.keys(draft.selectedPackages).forEach(key => {
        if (draft.allPackages[key].type == 'water') {
            packageId = draft.allPackages[key].id;
        }
    });

    return packageId;
}

/**
 * Checks if there is a water package in current draft. 
 * @param {} draft 
 * @returns 
 */
function isAnyWaterPackageSelected(draft) {
    if (Object.keys(draft.allPackages).length == 0) {
        return false;
    }

    if (Object.keys(draft.selectedPackages).length > 0) {
        for (let selectedWaterPack in draft.selectedPackages) {
            if (isWaterPackage(draft.allPackages[selectedWaterPack])) {
                return true;
            }
        }
    }

    if (Object.keys(draft.selectedPackagesInOrder).length > 0) {
        for (let selectedWaterPackInOrder in draft.selectedPackagesInOrder) {
            if (isWaterPackage(draft.allPackages[selectedWaterPackInOrder])) {
                return true;
            }
        }
    }

    return false;
}

function isWaterPackage(item) {
    return item.type == 'water' || item.type == 'water-ex'
}

const initialState = {
    activeProductCategoryTab: 'res', // com , dis
    activeProductClassificationTab: 'con29',
    activeLALetterTab: 'la-a',
    allPackages: {}, // dictionary of packages
    groupByPackages: {}, // packages group by category
    recommendedDatasource: [],
    isRecommendedLoading: false,
    recommendedCommercialList: [],
    recommendedResidentialList: [],
    favoriteDatasource: [],
    favoriteDatasourceList: [],
    productDatasource: [],
    laAuthorityPackagesForSelectedAddress: {},
    laAuthorityDatasource: {},
    laAuthorityOtherDatasource: [],
    discountPackagesDatasource: [],
    selectedPackagesInOrder: {}, // these are packages which are already added to order. This props is used when we need to add more packages
    selectedDiscountPackagesInOrder: {}, // these are packages which are already added to order. This props is used when we need to add more packages
    //
    // Key: packageId, value: true
    // this is a dictionary of selected packages. if discount bundle is selcted with local authority search
    // the real package will be here while discount package will keep original package ids
    selectedPackages: {},
    //
    // this is bundle package for discount. it keeps packages in subpackage property
    selectedDiscountPackageCore: {},
    contactPerson: {},
    discountPackagesOpen: false,
    discountPackagesDialogDatasource: [],
    vatRate: 20,
    additionalInfoSelectedPackageSourse: {},
    localAuthoritySearchPackageId: -1,
    isAnyWaterPackageSelected: false
};

export function createProductReducer(state = initialState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOAD_CUSTOMER_CONTACTS_SUCCESS:
                var primaryContact = find(action.values, ['isPrimaryContact', true]);
                if (primaryContact != undefined) {
                    draft.contactPerson = primaryContact;
                }

                break;

            case Actions.ADD_PACKAGES_INIT_STATE:
                var newSelected = {};
                var packages = action.state.packages;
                packages.forEach((item, index) => {
                    newSelected[item.id] = true;
                });

                draft.selectedPackagesInOrder = newSelected;
                draft.selectedDiscountPackagesInOrder = { id: action.state.discountPackages };
                draft.isAnyWaterPackageSelected = isAnyWaterPackageSelected(draft);
                break;

            case Actions.NEW_ORDER_DISCOUNT_PACKAGE_LOADED:
                draft.discountPackagesDialogDatasource = action.packages;
                break;

            case Actions.NEW_ORDER_DISCOUNT_PACKAGES_ADD_SELECTED:

                action.packages.forEach(item => {
                    draft.selectedPackages[item.id] = {
                        value: true,
                        additionalProductInfo: {},
                        discount: { isDiscountPackage: true, discountAmount: item.discountAmount }
                    };
                });

                break;

            case Actions.NEW_ORDER_DISCOUNT_PACKAGES_DELETE:
                delete draft.selectedPackages[action.packageId];
                break;

            //case Actions.NEW_ORDER_SAVE_QUOTE_SUCCESS:
            //case Actions.NEW_ORDER_SUBMIT_SUCCESS:
            case Actions.NEW_ORDER_INIT_STATE:
            case Actions.ADD_PACKAGES_SUCCESS:
                draft.selectedPackages = {}
                draft.selectedDiscountPackageCore = {};
                draft.selectedPackagesInOrder = {};
                draft.selectedDiscountPackagesInOrder = {};
                draft.additionalInfoSelectedPackageSourse = {};
                draft.contactPerson = { id: -1 };
                draft.recommendedDatasource = [];
                draft.recommendedCommercialList = [];
                draft.recommendedResidentialList = [];
                draft.discountPackagesOpen = false;
                draft.localAuthoritySearchPackageId = -1;
                draft.alerts = {};
                draft.laAuthorityPackagesForSelectedAddress = {};;
                break;

            case Actions.NEW_ORDER_DISCOUNT_PACKAGE_OPEN:
                draft.discountPackagesOpen = true;
                draft.discountPackagesDialogDatasource = [];
                break;

            case Actions.NEW_ORDER_DISCOUNT_PACKAGE_CLOSE:
                draft.discountPackagesOpen = false;
                break;

            case Actions.CHANGE_ACTIVE_PRODUCT_CATEGORY_TAB:
                var packages = state.groupByPackages;
                var recommendedList = state.recommendedDatasource;
                var classification = action.tabName == 'res' ? 'con29' : 'comCon29';
                if (action.tabName == 'dis') {
                    draft.activeProductCategoryTab = action.tabName;
                    draft.discountPackagesDatasource = packages[action.tabName];
                    return;
                }
                else if (action.tabName == 'res') {
                    recommendedList = state.recommendedResidentialList;
                }
                else if (action.tabName == 'com') {
                    recommendedList = state.recommendedCommercialList;
                }

                var newFavorites = replaceLAInFavorite(recommendedList, state.favoriteDatasourceList, action.tabName);
                draft.activeProductCategoryTab = action.tabName;
                draft.favoriteDatasource = getFavoritePackagesByType(newFavorites, action.tabName);
                draft.activeProductClassificationTab = classification;
                draft.recommendedDatasource = recommendedList;
                draft.productDatasource = packages[action.tabName][classification];
                break;

            case Actions.CHANGE_ACTIVE_PRODUCT_CLASSIFICATION_TAB:
                var categoryTab = state.activeProductCategoryTab;
                var packages = state.groupByPackages;
                var activePackages = packages[categoryTab][action.tabName];
                draft.activeProductClassificationTab = action.tabName;
                if (action.tabName == 'authority') {
                    var activeLATab = state.activeLALetterTab;
                    draft.laAuthorityDatasource = activePackages;
                    draft.laAuthorityOtherDatasource = activePackages.optional;
                    draft.productDatasource = activePackages[activeLATab];
                }
                else {
                    draft.productDatasource = activePackages;
                }

                break;

            case Actions.CHANGE_ACTIVE_LA_LETTER_TAB:
                var categoryTab = state.activeProductCategoryTab;
                var packages = state.groupByPackages;
                var activePackages = packages[categoryTab].authority[action.tabName];
                draft.activeLALetterTab = action.tabName;
                draft.productDatasource = activePackages;
                break;

            case Actions.LOAD_PACKAGE_SALE_INFO_SUCCESS:
                var pacakgeCategory = state.activeProductCategoryTab;
                var pacakgeClassification = state.activeProductClassificationTab;
                var groupByPackages = action.madePackages;
                var activePackages = groupByPackages[pacakgeCategory][pacakgeClassification];
                var packageCategoryTab = state.activeProductCategoryTab;

                var alerts = state.alerts;
                if (keys(alerts).length > 0) {
                    // this means that alerts were loaded before all packages info
                    // in this case we need to set alerts correct
                    var residentialAlerts = alerts.residentialAlerts;
                    var commercialAlerts = alerts.commercialAlerts;
                    var resRec = setRecommended([...state.recommendedResidentialList], residentialAlerts, null, action.packages);
                    var comRec = setRecommended([...state.recommendedCommercialList], commercialAlerts, null, action.packages);
                    draft.recommendedResidentialList = resRec;
                    draft.recommendedCommercialList = comRec;
                }

                var recommendedList = state.recommendedCommercialList;
                if (packageCategoryTab == 'res') {
                    recommendedList = state.recommendedResidentialList;
                }
                var newFavorites = action.favoritePackages.filter(x => typeof x !== 'undefined');
                draft.allPackages = action.packages;
                draft.groupByPackages = groupByPackages;
                draft.productDatasource = activePackages;
                draft.favoriteDatasource = getFavoritePackagesByType(newFavorites, packageCategoryTab);
                draft.favoriteDatasourceList = newFavorites;
                draft.recommendedDatasource = recommendedList;
                draft.vatRate = action.vatRate;
                draft.isAnyWaterPackageSelected = isAnyWaterPackageSelected(draft);
                break;

            case Actions.SELECT_PACKAGE: // regular packages
                if (action.value) {
                    // select package
                    draft.selectedPackages[action.packageId] = {
                        value: true,
                        additionalProductInfo: {},
                        discount: {},
                        termsRequired: action.termsRequired,
                        termsAndConditions: false
                    };

                    draft.isAnyWaterPackageSelected = isAnyWaterPackageSelected(draft);
                    return;
                }

                // deselect package
                if (isWaterPackage(state.allPackages[action.packageId])) {
                    // means we deselect water package. so clear flag
                    draft.isAnyWaterPackageSelected = false;
                }
                delete draft.selectedPackages[action.packageId];
                break;

            // User choose to switch to package which we recommended, Like coal out of area.
            // this is done in Switch dialog 1246, 1284
            case Actions.SWITCH_TO_SUGGESTED_PACKAGE:
                if (Array.isArray(action.oldPackageId)) {
                    action.oldPackageId.forEach(pId => {
                        delete draft.selectedPackages[pId];
                    })
                }
                else {
                    delete draft.selectedPackages[action.oldPackageId];
                }

                draft.selectedPackages[action.newPackageId] = {
                    value: true,
                    additionalProductInfo: {},
                    discount: {},
                    termsRequired: action.termsRequired,
                    termsAndConditions: false
                };
                break;

            case Actions.SELECT_ALL_FAVORITE_PACKAGE:
                let fav = state.favoriteDatasource;
                let selectedWaterPackageId = getSelectedWaterPackageId(state);
                fav.forEach(item => {
                    var packageId = item.id;
                    var products = item.products;
                    var termsRequired = false;
                    if (products !== undefined && products !== null) {
                        products.forEach(product => {
                            termsRequired = product.termsRequired;
                        });
                    }

                    if (packageId) {
                        if (action.value) {
                            if (isWaterPackage(item) &&
                                (state.isAnyWaterPackageSelected || draft.isAnyWaterPackageSelected)) {
                                // return if we already have package (state) or now we selected it (draft)
                                return;
                            }
                            else {
                                draft.selectedPackages[packageId] = {
                                    value: true,
                                    additionalProductInfo: {},
                                    discount: {},
                                    termsRequired: termsRequired,
                                    termsAndConditions: false
                                };
                                if (isWaterPackage(item)) {
                                    draft.isAnyWaterPackageSelected = true;
                                }
                            }
                        }
                        else {
                            // deselect. Clear flag only if package we deselect has been selected already
                            if (item.id == selectedWaterPackageId) {
                                draft.isAnyWaterPackageSelected = false;
                            }

                            delete draft.selectedPackages[packageId];
                        }
                    }
                });

                break;

            case Actions.SELECT_DISCOUNT_PACKAGE: // main discount package
                var allPackages = state.allPackages;
                var pack = allPackages[action.packageId];
                if (action.value) {
                    pack.subPackages.forEach(item => {
                        var localAuthPackId = state.localAuthoritySearchPackageId;
                        var subPackageId = item.subPackageId;
                        if (item.isLocalAuthoritySearch && localAuthPackId > -1) {
                            subPackageId = localAuthPackId;
                        }

                        draft.selectedPackages[subPackageId] = {
                            value: true,
                            additionalProductInfo: {},
                            discount: {},
                            isLocalAuthorityPackageInDiscountBundle: item.isLocalAuthoritySearch
                        };
                    });

                    var map = {}
                    map[action.packageId] = true;
                    draft.selectedDiscountPackageCore = map;
                }
                else {
                    pack.subPackages.forEach(item => {
                        var subPackageId = item.subPackageId;
                        var localAuthPackId = state.localAuthoritySearchPackageId;
                        if (item.isLocalAuthoritySearch && localAuthPackId > -1) {
                            subPackageId = localAuthPackId;
                        }

                        delete draft.selectedPackages[subPackageId];
                    });

                    Object.keys(draft.selectedPackages).forEach(item => {
                        draft.selectedPackages[item].discount = {};
                    });

                    draft.selectedDiscountPackageCore = {};
                }
                break;

            case Actions.TOGGLE_FAVORITE_PACKAGE:
                var favoriteDatasourceList = state.favoriteDatasourceList;
                var allPackages = state.allPackages;
                let index3 = favoriteDatasourceList.findIndex(item => {
                    return item.id == action.packageId;
                });

                if (index3 == -1) {
                    // add to favorite
                    var newArray = [...state.favoriteDatasourceList];
                    newArray.splice(0, 0, allPackages[action.packageId]);
                    draft.favoriteDatasourceList.splice(index3, 0, allPackages[action.packageId]);
                    draft.favoriteDatasource = getFavoritePackagesByType(newArray, state.activeProductCategoryTab);
                    return;
                }
                // remove
                draft.favoriteDatasourceList.splice(index3, 1);
                draft.favoriteDatasource = getFavoritePackagesByType(draft.favoriteDatasourceList, state.activeProductCategoryTab);
                break;

            case Actions.SET_FAVORITE_PACKAGES_AFTER_ERROR:
                var allPackages = state.allPackages;
                var fpList = [];
                action.favoritePackages.forEach((item, index) => {
                    fpList.splice(index, 0, allPackages[item.packageId]);
                });

                draft.favoriteDatasource = getFavoritePackagesByType(fpList, state.activeProductCategoryTab);
                draft.favoriteDatasourceList = fpList;
                break;

            case Actions.NEW_ORDER_SELECT_CONTACT_PERSON:
                draft.contactPerson = action.id;
                break;

            // loads quote and order
            case Actions.QUOTE_DETAILS_LOAD_QUOTE_SUCCESS:
                var selectedPackages = {};
                action.quote.packages.forEach(p => {
                    var additionalInfo = {};
                    p.additionalInfo.map(i => {
                        var extraInfo = JSON.parse(i.extraInfo);
                        additionalInfo[i.id] = {
                            tm: extraInfo.tm,
                            text: extraInfo.text
                        };
                    });

                    var selPack = {
                        value: true,
                        additionalProductInfo: additionalInfo,
                        isLocalAuthorityPackageInDiscountBundle: p.isLocalAuthorityPackageInDiscountBundle,
                        discount: { isDiscountPackage: p.isDiscountApplied || false, discountAmount: p.discountAmount }
                    };
                    selectedPackages[p.id] = selPack;
                })

                // do all this to keep packageId integer otherwise it is converted to string
                var selectedPackagesMap = {};
                keys(selectedPackages).forEach(item => {
                    selectedPackagesMap[parseInt(item)] = selectedPackages[item];
                })
                // add discount package core
                var localAuthId = -1;
                if (action.quote.localAuthorityPackageId != undefined && action.quote.localAuthorityPackageId > -1) {
                    localAuthId = action.quote.localAuthorityPackageId;
                }

                draft.contactPerson = { id: action.quote.contactPersonId };
                draft.localAuthoritySearchPackageId = localAuthId;
                draft.selectedPackages = selectedPackagesMap;
                draft.selectedDiscountPackageCore = action.quote.discountPackages.map(p => [p.packageId, true]);
                break;

            case Actions.SHOW_PACKAGE_ADDITIONAL_INFO:
                var dsName = getDatasourceNameByGridName(action.gridName);
                var rowIndex = draft[dsName].findIndex(item => {
                    return item.id == action.packageId;
                });
                // add extra row to data source. This will be rendered by grid as additional info row
                draft[dsName].splice(rowIndex + 1, 0, {
                    rowTemplateKey: 'additionalInfoKey',
                    data: { isLoading: true, showAddressWarning: false }
                });

                var categoryTab = state.activeProductCategoryTab;
                var classTab = state.activeProductClassificationTab;

                draft.additionalInfoSelectedPackageSourse[action.packageId] = { gridName: action.gridName };

                if (dsName == 'laAuthorityOtherDatasource' || dsName == 'productDatasource') {
                    if (classTab == 'authority') {
                        draft.groupByPackages[categoryTab][classTab].optional = draft[dsName];
                        return;
                    }

                    draft.groupByPackages[categoryTab][classTab] = draft[dsName];
                    return;
                }

                break;

            case Actions.HIDE_PACKAGE_ADDITIONAL_INFO:
                var originalGridName = state.additionalInfoSelectedPackageSourse[action.packageId].gridName;
                var categoryTab = state.activeProductCategoryTab;
                var classTab = state.activeProductClassificationTab;
                var dsName = getDatasourceNameByGridName(originalGridName);
                var rowIndex = draft[dsName].findIndex(item => item.id == action.packageId);
                draft[dsName].splice(rowIndex + 1, 1);
                delete draft.additionalInfoSelectedPackageSourse[action.packageId]

                if (dsName == 'laAuthorityOtherDatasource' || dsName == 'productDatasource') {
                    if (classTab == 'authority') {
                        draft.groupByPackages[categoryTab][classTab].optional = draft[dsName];
                        return;
                    }

                    draft.groupByPackages[categoryTab][classTab] = draft[dsName];
                    return;
                }

                break;
            //
            // process after we get additional questions info from TM
            case Actions.PACKAGE_ADDITIONAL_INFO_SUCCESS:
                var dsName = getDatasourceNameByGridName(action.gridName);
                var rowIndex = draft[dsName].findIndex(item => item.id == action.packageId);
                var categoryTab = state.activeProductCategoryTab;
                var classTab = state.activeProductClassificationTab;
                var allPackages = state.allPackages;

                draft[dsName][rowIndex + 1].data.isLoading = false;
                draft[dsName][rowIndex + 1].data.items = action.extraInfo.priceList || {};
                draft[dsName][rowIndex + 1].data.type = action.extraInfo.searchType;
                draft[dsName][rowIndex + 1].data.package = allPackages[action.packageId];

                if (dsName == 'laAuthorityOtherDatasource' || dsName == 'productDatasource') {
                    if (classTab == 'authority') {
                        draft.groupByPackages[categoryTab][classTab].optional = draft[dsName];
                        return;
                    }
                    draft.groupByPackages[categoryTab][classTab] = draft[dsName];
                    return;
                }

                break;

            case Actions.PACKAGE_ADDITIONAL_INFO_ERROR:
                var dsName = getDatasourceNameByGridName(action.gridName); // this needs to be from grid name
                var rowIndex = draft[dsName].findIndex(item => item.id == action.packageId);
                var categoryTab = state.activeProductCategoryTab;
                var classTab = state.activeProductClassificationTab;

                draft[dsName][rowIndex + 1][data].isLoading = false;
                draft[dsName][rowIndex + 1][data].showAddressWarning = true;
                draft[dsName][rowIndex + 1][data].msg = action.errorMsg;
                draft[dsName][rowIndex + 1][data].items = [];

                if (dsName == 'laAuthorityOtherDatasource' || dsName == 'productDatasource') {
                    if (classTab == 'authority') {
                        draft.groupByPackages[categoryTab][classTab].optional = draft[dsName];
                        return;
                    }
                    draft.groupByPackages[categoryTab][classTab] = draft[dsName];
                    return;
                }

                break;

            case Actions.SHOW_PACKAGE_ADDITIONAL_INFO_ADDRESS_WARNING:
                var dsName = getDatasourceNameByGridName(action.gridName);// this needs to be from grid name
                var rowIndex = draft[dsName].findIndex(item => item.id == action.packageId);
                var categoryTab = state.activeProductCategoryTab;
                var classTab = state.activeProductClassificationTab;

                draft[dsName].splice(rowIndex + 1, 0, {
                    rowTemplateKey: 'additionalInfoKey',
                    data: {
                        isLoading: false,
                        showAddressWarning: true,
                        msg: 'Please select valid address in order to see these extra questions.'
                    }
                });

                draft.additionalInfoSelectedPackageSourse[action.packageId] = { gridName: action.gridName };

                if (dsName == 'laAuthorityOtherDatasource' || dsName == 'productDatasource') {
                    if (classTab == 'authority') {
                        draft.groupByPackages[categoryTab][classTab].optional = draft[dsName];
                        return;
                    }
                    draft.groupByPackages[categoryTab][classTab] = draft[dsName];
                    return;
                }

                break;;

            case Actions.SELECT_PACKAGE_CON29_OPTIONAL_ENQUIRY:
                var tmList = draft.selectedPackages[action.packageId].additionalProductInfo[action.productId];
                if (action.value) {
                    if (tmList == undefined) {
                        draft.selectedPackages[action.packageId].additionalProductInfo[action.productId] = { tm: [] };
                    }

                    draft.selectedPackages[action.packageId].additionalProductInfo[action.productId].tm.push(action.additional);
                    return;
                }

                let index4 = tmList.tm.findIndex((item) => {
                    return item['priceDetailsId'] == action.additional.priceDetailsId;
                });
                draft.selectedPackages[action.packageId].additionalProductInfo[action.productId].tm.splice(index4, 1);
                break;

            case Actions.SET_PACKAGE_PRODUCT_ADDITIONAL_TEXT:
                draft.selectedPackages[action.packageId].additionalProductInfo[action.productId] = {
                    ...draft.selectedPackages[action.packageId].additionalProductInfo[action.productId],
                    text: action.text
                };
                break;

            case Actions.SET_PACKAGE_PRODUCT_AGREE_CHECKBOX:
                draft.selectedPackages[action.packageId].termsAndConditions = action.checkbox;
                break;

            case Actions.SET_RECOMMENDED_LOCAL_AUTHORITY_PACKAGES:
                var allPackages = state.allPackages;
                var recommendedList = state.recommendedDatasource;
                var categoryTab = state.activeProductCategoryTab;
                var oldLocalLAPackages = state.laAuthorityPackagesForSelectedAddress;

                var resRec = setRecommended([...state.recommendedResidentialList], action.packageIds.residential, oldLocalLAPackages.residential, allPackages);
                var comRec = setRecommended([...state.recommendedCommercialList], action.packageIds.commercial, oldLocalLAPackages.commercial, allPackages);
                var recommendedList = comRec;
                //var localAuthoritySearchPackageId = action.packageIds.commercial != undefined ? action.packageIds.commercial[0] : -1;
                // discount package for LA are only residentials
                var localAuthoritySearchPackageId = action.packageIds.residential[0] != undefined ? action.packageIds.residential[0] : -1;
                if (categoryTab == 'res') {
                    recommendedList = resRec;
                }

                var newFavorites = replaceLAInFavorite(recommendedList, state.favoriteDatasourceList, categoryTab);

                Object.keys(state.selectedPackages).forEach((key /*key, value*/, index) => {
                    let entry = draft.selectedPackages[key];
                    if (entry.isLocalAuthorityPackageInDiscountBundle) {
                        // replace fake LLC package with real one
                        console.log('check this SET_RECOMMENDED_LOCAL_AUTHORITY_PACKAGES');
                        delete draft.selectedPackages[index];
                        draft.selectedPackages[action.packageIds.residential[0]] = {
                            value: true,
                            additionalProductInfo: {},
                            discount: {},
                            isLocalAuthorityPackageInDiscountBundle: true
                        };
                    }
                });

                draft.recommendedDatasource = recommendedList;
                draft.recommendedResidentialList = resRec;
                draft.recommendedCommercialList = comRec;
                draft.laAuthorityPackagesForSelectedAddress = action.packageIds /*localLAPackages*/;
                draft.localAuthoritySearchPackageId = localAuthoritySearchPackageId;
                draft.favoriteDatasource = getFavoritePackagesByType(newFavorites, categoryTab);
                break;

            case Actions.SET_RECOMMENDED_ALERTS_PACKAGES:
                // called from Address change to load alerts
                // and from quote loaded when app loads quote
                var allPackages = state.allPackages;
                var packageCategoryTab = state.activeProductCategoryTab;
                var activeProductClassificationTab = state.activeProductClassificationTab;
                var residentialAlerts = state.alerts.residentialAlerts;
                var commercialAlerts = state.alerts.commercialAlerts;
                var resRec = setRecommended([...state.recommendedResidentialList], action.packages.residentialAlerts, residentialAlerts, allPackages);
                var comRec = setRecommended([...state.recommendedCommercialList], action.packages.commercialAlerts, commercialAlerts, allPackages);
                var recommendedList = comRec;
                if (packageCategoryTab == 'res') {
                    recommendedList = resRec;
                }
                var groupByPackagesUpdated = getProductDatasource(action.packages,/* {...state.groupByPackages}*/draft, allPackages);
                //set productDatasource
                //var packages = state.groupByPackages;
                //var allPackages = state.allPackages;
                //var recommendedList = state.recommendedDatasource;
                //var classification = action.tabName == 'res' ? 'con29' : 'comCon29';
                var activePackages = groupByPackagesUpdated[packageCategoryTab][activeProductClassificationTab];
                if (activeProductClassificationTab == 'authority') {
                    var activeLATab = state.activeLALetterTab;
                    draft.laAuthorityDatasource = activePackages;
                    draft.laAuthorityOtherDatasource = activePackages.optional;
                    draft.productDatasource = activePackages[activeLATab];
                }
                else {
                    draft.productDatasource = activePackages;
                }
                draft.alerts = action.packages; // store alerts
                draft.groupByPackages = groupByPackagesUpdated;
                draft.recommendedDatasource = recommendedList;
                draft.recommendedResidentialList = resRec;
                draft.recommendedCommercialList = comRec;
                //draft.productDatasource = activePackages; // update active package source with water packages from other companies if any
                draft.isRecommendedLoading = false;
                break;

            // set alerts for already created order. Order has only one type of alerts Res or Comm
            // So we dont need to worry how to set res and com alerts
            case Actions.SET_ALERTS_PACKAGES_FOR_ORDER:
                var recommendedPacks = action.packages;
                draft.recommendedDatasource = recommendedPacks;
                draft.recommendedResidentialList = recommendedPacks;
                draft.recommendedCommercialList = recommendedPacks;
                break;

            // Addition packages
            case Actions.NEW_ORDER_ADDRESS_SELECTED:
                draft.recommendedDatasource = [];
                break;

            case Actions.NEW_ORDER_SET_ALERTS_LOADING:
                draft.isRecommendedLoading = true;
                    break;

            case Actions.RESET_LOADING_ALERTS:
                draft.isRecommendedLoading = false;
                break;
        }
    });
}

export function createOrderUiReducer(state = {
    showSwitchPackageDlg: false,
    switchDlgSource: [],
    switchDlgOpenFor: {}, // coal or water. Contains value for what was dlg opened. for coal package or water package. So if user cancel it we dont show it twice
    switchDlgAlreadyOpen: {}, // dictionary. keeps info if dialog has been shown to the user. So when user closes dlg this flag will be true and next time we will
    // not offer it
    coalPackages: [], // contains coal package info. If address is in coal zone or not
    otherCompaniesPackages: [], // correct water package for this address
    allPackages: {},
    isLoading: false,
    wizardStep: 1,
    visitedSteps: [1],
    isCreating: false,
    openClassName: '',
    isMapLocked: false,
    showAlertForSave: false,
    isBusy: false,
    activeProductCategoryTab: 'res',
    activeProductClassificationTab: 'con29',
    warningPackages: [],
    validationMsgs: {}, //saveMap, editAddress, terms
    mapSaveDiscardAction: false
}, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case Actions.CHANGE_ACTIVE_PRODUCT_CATEGORY_TAB:
                var classification = action.tabName == 'res' ? 'con29' : 'comCon29';
                draft.activeProductCategoryTab = action.tabName;
                draft.activeProductClassificationTab = classification;
                break;

            case Actions.CHANGE_ACTIVE_PRODUCT_CLASSIFICATION_TAB:
                draft.activeProductClassificationTab = action.tabName;
                break;

            case Actions.LOAD_PACKAGE_SALE_INFO_SUCCESS:
                var newFavorites = action.favoritePackages.filter(x => typeof x !== 'undefined');
                draft.allPackages = action.packages;
                draft.favoriteDatasourceList = newFavorites;
                break;

            case Actions.SET_RECOMMENDED_ALERTS_PACKAGES:
                draft.coalPackages = action.packages.coals;
                draft.otherCompaniesPackages = action.packages.waterPackagesOtherCompanies;
                draft.switchDlgAlreadyOpen = {}; // clear switch flags
                break;

            case Actions.MAP_USER_ACTION:
                // user saved map. Clear validation message if any
                draft.mapSaveDiscardAction = action.mapSaveDiscardAction;
                if (action.mapSaveDiscardAction) {
                    delete draft.validationMsgs['saveMap'];
                }
                break;

            case Actions.ADD_VALIDATION_MESSAGE:
                draft.validationMsgs[action.key] = action.message;
                break;

            case Actions.REMOVE_VALIDATION_MESSAGE:
                delete draft.validationMsgs[action.key];
                break;
            case Actions.SELECT_ALL_FAVORITE_PACKAGE:
                if (action.value != true) {
                    // user remove package so do nothing
                    return;
                }

                // check if some package needs
                var showSwitchPackageDlg = false;
                var switchDlgWaterSource = null;
                var switchDlgCoalSource = null;
                var dlgTitle = 'This property is NOT located within a coal mining area.';
                var allPackages = state.allPackages;
                var coalPackages = state.coalPackages;
                var otherCompaniesPackages = state.otherCompaniesPackages;
                var activeProductCategoryTab = state.activeProductCategoryTab;
                var activeProductClassificationTab = state.activeProductClassificationTab;
                var fpList = state.favoriteDatasourceList;
                var currentFavoritePackages = getFavoritePackagesByType(fpList, activeProductCategoryTab);
                //////
                // check if there is water package in selected favorite
                var waterPack = currentFavoritePackages.filter(p => isWaterPackage(p));
                var suggestedPackage = otherCompaniesPackages.filter(p => p.category == activeProductCategoryTab && p.classification == activeProductClassificationTab)[0];

                let isAnyWaterPackage = isAnyWaterPackageSelected({
                    allPackages: allPackages,
                    selectedPackages: action.selectedPackages,
                    selectedPackagesInOrder: action.selectedPackagesInOrder
                });

                // Check for suggestions only if there is not water pack selected already
                if (waterPack.length > 0 && suggestedPackage && !isAnyWaterPackage) {
                    // there are water packages. Now lets check if those are correct
                    var isSuggestedPackageIn = waterPack.filter(p => p.id == suggestedPackage.packageId);
                    if (isSuggestedPackageIn.length == 0 ||  // this means that correct water package is not selected. Show dlg to switch to correct one
                        (isSuggestedPackageIn.length > 0 && waterPack.length > 1)) // this means user has selected correct water package. But there could be more than one water packages in favorite like STW and Anglian Water
                    {
                        var suggestedPackageFull = allPackages[suggestedPackage.packageId];
                        var waterPackIds = waterPack.map(p => p.id);
                        showSwitchPackageDlg = true;
                        switchDlgWaterSource = {
                            dlgTitle: 'We have identified that the CON29 search provider for your search address is not correct.',
                            dataSource: [
                                { message: `Switch to ${suggestedPackageFull.name}`, action: 'Switch', actionData: { switchTo: suggestedPackageFull.id, delete: waterPackIds } },
                                { message: `Proceed with current selected packages (Not recommended).`, action: 'Select', actionData: { delete: waterPackIds } }
                            ]
                        }
                        // // and border all wrong packages in red
                        // waterPack.forEach(item => {
                        //     draft.warningPackages.push(item.id);
                        // })
                    }
                }

                if (coalPackages.length > 0) {
                    // check coal packages
                    var coalPack = currentFavoritePackages.filter(p => p.type == 'coal');
                    var nocoalPack = currentFavoritePackages.filter(p => p.type == 'no-coal');
                    var isAddressInCoal = coalPackages[0].inCoal;
                    if (coalPack.length > 0 && isAddressInCoal == false) {
                        // user selected coal package but address is not in coal. Then advise to switch to no coal package
                        showSwitchPackageDlg = true;
                        //var correctCoalPackage = coalPackages.filter(p => p.category == activeProductCategoryTab)[0];
                        //var correctCoalPackageFull = allPackages[correctCoalPackage.packageId];
                        var coalPackFull = allPackages[coalPack[0].id];
                        var correctCoalPackage = coalPackages.filter(p => p.category == activeProductCategoryTab && p.triggerByPackageId == coalPackFull.id);
                        var correctCoalPackageFull = allPackages[correctCoalPackage[0].packageId];

                        switchDlgCoalSource = {
                            dlgTitle: 'This property is NOT located within a coal mining area.',
                            dataSource: [
                                { message: `Switch to ${correctCoalPackageFull.name}`, action: 'Switch', actionData: { switchTo: correctCoalPackageFull.id, delete: coalPackFull.id } },
                                { message: `Proceed with ${coalPackFull.name}`, action: 'Select', actionData: { delete: coalPackFull.id } }
                            ]
                        };

                        draft.warningPackages.push(coalPackFull.id);
                    }

                    if (nocoalPack.length > 0 && isAddressInCoal == true) {
                        // user selected no coal package but address is in coal. Then advise to switch to coal package
                        showSwitchPackageDlg = true;
                      
                        var nocoalPackFull = allPackages[nocoalPack[0].id];
                        var correctNoCoalPackage = coalPackages.filter(p => p.category == activeProductCategoryTab && p.triggerByPackageId == nocoalPackFull.id);
                        var correctNoCoalPackageFull = allPackages[correctNoCoalPackage[0].packageId];

                        switchDlgCoalSource = {
                            dlgTitle: 'This property IS located within a coal mining area.',
                            dataSource: [
                                { message: `Switch to ${correctNoCoalPackageFull.name}`, action: 'Switch', actionData: { switchTo: correctNoCoalPackageFull.id, delete: nocoalPackFull.id } },
                                { message: `Proceed with ${nocoalPackFull.name}`, action: 'Select', actionData: { delete: nocoalPackFull.id } }
                            ]
                        };

                        draft.warningPackages.push(nocoalPackFull.id);
                    }
                }

                var switchDlgSource = [];
                if (switchDlgWaterSource) {
                    switchDlgSource.push(switchDlgWaterSource);
                }
                if (switchDlgCoalSource) {
                    switchDlgSource.push(switchDlgCoalSource);
                }
                draft.showSwitchPackageDlg = showSwitchPackageDlg;
                draft.switchDlgSource = switchDlgSource;
                break;

            case Actions.SELECT_PACKAGE:
                if (action.value != true) {
                    // user remove package so do nothing
                    return;
                }

                // check if some package needs
                var showSwitchPackageDlg = false;
                var switchDlgSource = [];
                var dlgTitle = 'This property is NOT located within a coal mining area.';
                var allPackages = state.allPackages;
                var otherCompaniesPackages = state.otherCompaniesPackages;
                var activeProductCategoryTab = state.activeProductCategoryTab;
                var activeProductClassificationTab = state.activeProductClassificationTab;
                var selectedPackage = allPackages[action.packageId];
                var correctWaterPackage = [];
                if (selectedPackage.type == 'water') {
                    // check if this is the right water package for this address
                    // otherCompaniesPackages has information about area and what the correct package is
                    var check = otherCompaniesPackages.filter(p => p.packageId == action.packageId && p.category == activeProductCategoryTab && p.classification == activeProductClassificationTab);
                    if (check.length == 0) {
                        // this means user selected water package which is not for this address
                        dlgTitle = 'We have identified that the CON29 search provider for your search address is not correct.';
                        correctWaterPackage = otherCompaniesPackages.filter(p => p.category == activeProductCategoryTab && p.classification == activeProductClassificationTab);
                    }
                }

                // get if address is in coal area
                var coalPackages = state.coalPackages;
                if (coalPackages.length > 0) {
                    var isAddressInCoal = coalPackages[0].inCoal;
                    if (selectedPackage.type == 'coal' && isAddressInCoal == false) {
                        // user selected coal package but address is not in coal. Then advise to switch to no coal package
                        correctWaterPackage = coalPackages.filter(p => p.category == activeProductCategoryTab && p.triggerByPackageId == selectedPackage.id);
                        dlgTitle = 'This property is NOT located within a coal mining area.';

                        // show warning in basket only if this is coal
                        draft.warningPackages.push(action.packageId);
                    }

                    if (selectedPackage.type == 'no-coal' && isAddressInCoal == true) {
                        // user selected no coal package but address is in coal. Then advise to switch to coal package
                        correctWaterPackage = coalPackages.filter(p => p.category == activeProductCategoryTab && p.triggerByPackageId == selectedPackage.id);
                        dlgTitle = 'This property IS located within a coal mining area.';

                        // show warning in basket only if this is coal
                        draft.warningPackages.push(action.packageId);
                    }
                }

                if (correctWaterPackage.length > 0) {
                    let switchDlgAlreadyOpen = state.switchDlgAlreadyOpen;
                    let selectedPack = allPackages[action.packageId];
                    if (switchDlgAlreadyOpen[action.packageId] == true) {
                        // we already suggested this to client so do nothing now.
                        return;
                    }

                    let suggestedPack = allPackages[correctWaterPackage[0].packageId];
                    showSwitchPackageDlg = true;
                    switchDlgSource = {
                        dlgTitle: dlgTitle,
                        dataSource: [
                            { message: `Switch to ${suggestedPack.name}`, action: 'Switch', actionData: { switchTo: suggestedPack.id, delete: selectedPack.id } },
                            { message: `Proceed with ${selectedPack.name}`, action: 'Select', actionData: { delete: selectedPack.id } }
                        ]
                    }
                }

                draft.showSwitchPackageDlg = showSwitchPackageDlg;
                draft.switchDlgSource = [switchDlgSource];
                break;

            case Actions.SWITCH_TO_SUGGESTED_PACKAGE:
                var switchDlgSource = [...state.switchDlgSource];
                switchDlgSource.splice(action.sectionIndex, 1);
                var showDlg = switchDlgSource.length > 0;

                if (Array.isArray(action.oldPackageId)) {
                    action.oldPackageId.forEach(pId => {
                        var index = draft.warningPackages.findIndex(id => {
                            return id == pId
                        });
                        if (index > -1) {
                            draft.warningPackages.splice(index, 1);
                        }
                    })
                }
                else {
                    var index = draft.warningPackages.findIndex(id => {
                        return id == action.oldPackageId
                    });
                    if (index > -1) {
                        draft.warningPackages.splice(index, 1);
                    }
                }

                draft.showSwitchPackageDlg = showDlg;
                draft.switchDlgSource = switchDlgSource;
                break;

            case Actions.CLOSE_SWITCH_PRODUCT_DIALOG:
                let switchDlgAlreadyOpen = draft.switchDlgAlreadyOpen;
                switchDlgAlreadyOpen[action.packageId] = true;
                var switchDlgSource = [...state.switchDlgSource];
                switchDlgSource.splice(action.sectionIndex, 1);
                var showDlg = switchDlgSource.length > 0;

                draft.showSwitchPackageDlg = showDlg;
                draft.switchDlgSource = switchDlgSource;
                draft.switchDlgAlreadyOpen = switchDlgAlreadyOpen;
                break;

            case Actions.NEW_ORDER_LOAD_OERDER_PACKAGES:
                draft.isLoading = true;
                break;

            case Actions.ADD_PACKAGES_INIT_STATE:
                draft.isLoading = false;
                break;

            case Actions.NEW_ORDER_WIZARD_STEP:
                var visitedSteps = state.visitedSteps;
                var index = visitedSteps.indexOf(action.step);
                if (index == -1) {
                    draft.visitedSteps.splice(0, 0, action.step);;
                }

                draft.wizardStep = action.step;
                break;

            case Actions.NEW_ORDER_SAVE_QUOTE_SUCCESS:
            case Actions.NEW_ORDER_SUBMIT_SUCCESS:
            case Actions.NEW_ORDER_INIT_STATE:
            case Actions.ADD_PACKAGES_SUCCESS:
                draft.visitedSteps = [1];
                draft.wizardStep = 1;
                draft.isMapLocked = false;
                draft.showAlertForSave = false;
                draft.openClassName = '';
                break;

            case Actions.NEW_ORDER_SUBMIT:
            case Actions.NEW_ORDER_SAVE_QUOTE:
            case Actions.ADD_PACKAGES_REQUEST:
                draft.isCreating = false;
                break;

            case Actions.NEW_ORDER_SUBMIT_FAILED:
            case Actions.NEW_ORDER_SAVE_QUOTE_ERROR:
            case Actions.ADD_PACKAGES_ERROR:
                draft.isCreating = true;
                break;

            case Actions.NEW_ORDER_SHOW_BASKET_ON_MOBILE:
                draft.openClassName = 'open';
                break;

            case Actions.NEW_ORDER_HIDE_BASKET_ON_MOBILE:
                draft.openClassName = '';
                break;

            case Actions.NEW_ORDER_TOGGLE_MAP_SAVE_BUTTON:
                draft.showAlertForSave = action.value;
                if (action.value) {
                    delete draft.validationMsgs['saveMap'];
                }
                break;

            case UploadActions.UPLOAD_SUCCESS:
                if (action.fileName === 'property-plan.png') {
                    // lock map if we successfully uploaded plan
                    draft.isMapLocked = true;
                }
                break;

            case UploadActions.UPLOAD_DELETE_SUCCESS:
                if (action.fileName === 'property-plan.png') {
                    // lock map if we successfully uploaded plan
                    draft.isMapLocked = false;
                    draft.showAlertForSave = true;
                }
                break;
            case Actions.IS_BUSY:
                draft.isBusy = action.isBusyState;
                break;
        }
    });
}

export function createAddressReducer(state = {
    selectedAddress: {},
    showSaveTooltip: false,
    howIsAddressEntered: 'none', // could be 'gbg' or 'manual' or 'none'
}, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case Actions.NEW_ORDER_SAVE_QUOTE_SUCCESS:
            case Actions.NEW_ORDER_SUBMIT_SUCCESS:
            case Actions.NEW_ORDER_INIT_STATE:
            case Actions.ADD_PACKAGES_SUCCESS:
                draft.selectedAddress = {};
                draft.howIsAddressEntered = 'none';
                break;

            case Actions.NEW_ORDER_ADDRESS_SELECTED:
                draft.selectedAddress = action.address;
                draft.howIsAddressEntered = action.validation;
                draft.showSaveTooltip = false;
                break;
            case Actions.SHOW_ADDRESS_SAVE_TOOLTIP:
                draft.showSaveTooltip = action.value;
                break;

            case Actions.NEW_ORDER_ADDRESS_VALIDATION_CHANGED:
                draft.howIsAddressEntered = action.validation;
                draft.showSaveTooltip = false;
                break;

            case Actions.QUOTE_DETAILS_LOAD_QUOTE_SUCCESS:
                const address = {
                    subbuilding: action.quote.flatNo,
                    buildingNumber: action.quote.houseNo,
                    buildingName: action.quote.houseName,
                    thoroughfare: action.quote.street,
                    locality: action.quote.locality,
                    organisation: action.quote.organisation,
                    town: action.quote.city,
                    postcode: action.quote.postalCode,
                    easting: action.quote.gridReference ? action.quote.gridReference.substring(0, 6) : '',
                    northing: action.quote.gridReference ? action.quote.gridReference.substring(6, 12) : '',
                    uprn: action.quote.uprn,
                    udprn: action.quote.udprn
                };

                draft.howIsAddressEntered = action.quote.howIsAddressEntered;
                draft.selectedAddress = address;
                break;

            case Actions.SET_ADDRESS_FOR_MORE_PRODUCTS:
                const eastingNorthing = {
                    easting: action.address ? action.address.substring(0, 6) : '',
                    northing: action.address ? action.address.substring(6, 12) : ''
                };

                draft.howIsAddressEntered = 'gbg';
                draft.selectedAddress = eastingNorthing;
                break;
        }
    });
}

export function createAdditionalInfoReducer(state = {
    customerReference: '',
    developerName: '',
    siteName: '',
    additionalInfo: '',
    geometries: ''
}, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case Actions.NEW_ORDER_SAVE_QUOTE_SUCCESS:
            case Actions.NEW_ORDER_SUBMIT_SUCCESS:
            case Actions.NEW_ORDER_INIT_STATE:
            case Actions.ADD_PACKAGES_SUCCESS:
                draft.customerReference = '';
                draft.developerName = '';
                draft.siteName = '';
                draft.additionalInfo = '';
                break;

            case Actions.NEW_ORDER_SELECT_CUSTOMER_REFERENCE:
                draft.customerReference = action.custRef;
                break;

            case Actions.NEW_ORDER_SELECT_DEVELOPER_NAME:
                draft.developerName = action.name;
                break;

            case Actions.NEW_ORDER_SELECT_SITE_NAME:
                draft.siteName = action.name;
                break;

            case Actions.NEW_ORDER_SELECT_ADDITIONAL_INFO:
                draft.additionalInfo = action.info;
                break;

            case Actions.QUOTE_DETAILS_LOAD_QUOTE_SUCCESS:
                draft.developerName = action.quote.developerName;
                draft.siteName = action.quote.siteName;
                draft.additionalInfo = action.quote.additionalInfo;
                draft.customerReference = action.quote.customerRef;
                break;

            case Actions.NEW_GEOMETRIES:
                draft.geometries = action.geometries;
                break;

            case Actions.CLEAR_GEOMETRIES:
                draft.geometries = '';
                break;
        }
    });
}

export function createdOrderReducer(state = {
    createdOrder: {},
}, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case Actions.NEW_ORDER_SUBMIT_SUCCESS:
                draft.createdOrder = action.order;
                break;
        }
    });
}

export default combineReducers({
    packages: createProductReducer,
    ui: createOrderUiReducer,
    address: createAddressReducer,
    upload: createUploadReducer(action => { // shouldProcessCallback
        if (action.uploadChannel != undefined && action.uploadChannel != 'create-order-upload-channel') {
            return false;
        }
    }),
    additionalInfo: createAdditionalInfoReducer,
    createdOrder: createdOrderReducer
    //queryGrid: create('dashboardQueryGrid') // here is where datasource of query grid is set
});
