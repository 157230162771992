import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './actions';
import Dialog from '@mui/material/Dialog';
import Clear from '../../svg-icons/clear';

export class PreferencesContactsModalDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleNewOrderChange = this.handleNewOrderChange.bind(this);

        this.state = {
            open: !props.isNotShown,
            email: props.contact.email,
            firstName: props.contact.firstName,
            lastName: props.contact.lastName,
            phone: props.contact.phone,
            jobTitle: props.contact.jobTitle,
            newOrder: props.contact.newOrder
        };
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    handlePhoneChange(e) {
        this.setState({ phone: e.target.value });
    }

    handleRoleChange(e) {
        this.setState({ jobTitle: e.target.value });
    }

    handleNewOrderChange(e) {
        if (e) {
            this.setState({ newOrder: "template" });
        } else {
            this.setState({ newOrder: "wizard" });
        }
    }

    handleFirstNameChange(e) {
        this.setState({ firstName: e.target.value });
    }

    handleLastNameChange(e) {
        this.setState({ lastName: e.target.value });
    }

    renderMenuItems() {
        if (this.props.titles == undefined) {
            return "";
        }

        return this.props.titles.map(item =>
            <option key={item} value={item}>{item}</option>
        );
    }

    render() {
        var readOnly = false;
        if (this.props.isPrimaryContact) {
            readOnly = true;
        }

        return (
            <Dialog open={this.state.open}>
                <form id="contact-details-form" className="modal-form">
                    <button onClick={this.props.handleCancel} type="button" className="modal-close" data-demo="close-modal">
                        <Clear className="icon icon-clear" role="img" />
                    </button>
                    <div className="modal-title">
                        {this.props.contact.isPrimaryContact ? <span className="status-label primary-label">Primary Contact</span> : null}
                        <h1>{this.props.contact.name}</h1>
                    </div>
                    <div className="entry">
                        <label htmlFor="firstName">First Name</label>
                        <input id="firstName" maxLength="100" value={this.state.firstName} onChange={this.handleFirstNameChange} className="form-control-grey" type="text" readOnly={readOnly} />
                    </div>
                    <div className="entry">
                        <label htmlFor="lastName">Last Name</label>
                        <input id="lastName" maxLength="100" value={this.state.lastName} onChange={this.handleLastNameChange} className="form-control-grey" type="text" readOnly={readOnly} />
                    </div>
                    <div className="entry">
                        <label htmlFor="email">E-mail Address</label>
                        <input id="email" maxLength="100" value={this.state.email} onChange={this.handleEmailChange} className="form-control-grey" type="text" readOnly={readOnly} />
                    </div>
                    <div className="entry form-control-60">
                        <label htmlFor="phone">Phone Number</label>
                        <input id="phone" placeholder="(XXXX) XXX XXXX" maxLength="20" value={this.state.phone} onChange={this.handlePhoneChange} className="form-control-grey" type="tel" readOnly={readOnly} />
                    </div>
                    <div className="entry">
                        <label htmlFor="phone">Role</label>
                        <div className="form-dropdown">
                            <select id="job-title" className="form-control-grey"
                                value={this.state.jobTitle}
                                disabled={readOnly}
                                onChange={this.handleRoleChange}>
                                {this.renderMenuItems()}
                            </select>
                        </div>
                    </div>
                    {this.props.isPrimaryContact ?
                        <div className="entry">
                            <label htmlFor="order-options">New Order</label>
                            <div className="form-control-options">
                                <label htmlFor="order-template" className="form-radio">
                                    <input id="order-template" name="order-options" onChange={this.handleNewOrderChange.bind(this, true)} checked={this.state.newOrder == "template"} type="radio" />
                                    <span className="indicator"></span>
                                    <span className="label">Use Order Template</span>
                                </label>
                                <label htmlFor="order-wizard" className="form-radio">
                                    <input id="order-wizard" name="order-options" onChange={this.handleNewOrderChange.bind(this, false)} checked={this.state.newOrder == "wizard"} type="radio" />
                                    <span className="indicator"></span>
                                    <span className="label">Use Order Wizard</span>
                                </label>
                            </div>
                        </div> :
                        null}

                    <div className="actions">
                        <button onClick={this.props.handleCancel} type="reset" className="button button-regular button-transparent" data-demo="close-modal">Cancel</button>
                        <button onClick={this.props.handleSave.bind(this, {
                            id: this.props.contact.id,
                            isPrimaryContact: this.props.isPrimaryContact,
                            email: this.state.email,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            phone: this.state.phone,
                            jobTitle: this.state.jobTitle,
                            newOrder: this.state.newOrder
                        })} type="reset" className="button button-regular button-green" data-demo="close-modal">Save Changes</button>
                    </div>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = createStructuredSelector({

})
const mapDispatchToProps = ({
    ...actions
})
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(PreferencesContactsModalDialog);
