
import React from 'react';
// import pure from 'recompose/pure';
import SvgIconEx from './SvgIconEx';

let Check = (props) => (
  <SvgIconEx  {...props}>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <polyline points="17,8.5 9.5,15.5 7,13"/><circle cx="12" cy="12" r="11.5"/>
      </g>
  </SvgIconEx>
);
Check.displayName = 'Check';
Check.muiName = 'SvgIcon';

export default Check;