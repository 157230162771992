import React from 'react';
import ReactGA from 'react-ga4';
import Attention from '../../svg-icons/attention';
import Pdf from '../../svg-icons/pdf';
import Info from '../../svg-icons/info';
import {createStructuredSelector} from 'reselect';
import {selectSelectedPackages, selectAllPackages, selectDiscountPackageCore, selectVatRate} from './selectors';
import {connect} from 'react-redux';
import keys from 'lodash/keys';
import {formatPrice} from '../../utils/format';
import PriceLines from './basket/priceLines';
import {calculateSLADate} from '../CreateOrder/basket/slaFunctions';

class WizardProductOrdered extends React.PureComponent {

  componentDidMount() {
      ReactGA.send({ hitType: "pageview", page: "/create-order-wizard" });
  }

  renderDocuments(docs) {
    var ui = docs.map((item, index) => {
      return (
        <a key={index} href={item.documentURL} target="_blank" className="text-link cell-wrapper">
          <Pdf className="icon icon-document icon-pdf"/>
          <div className="text-reference">{item.documentToolTipDescription}</div>
        </a>);
    });
    return ui;
  }

  renderProducts() {
    var list = keys(this.props.selectedPackageIds).map((packageId, index) => {
      var item = this.props.allPackages[packageId];
      var classEx = 'residential';
      if (item.category === 'Commercial' || item.category === 'CommercialPopular') {
        classEx = 'commercial'
      }

      return (
        <tr key={index}>
          <td>
            <div className="cell-wrapper">
              <div className={`text-heading color-${classEx}`}>{item.name}</div>
              <div className="popover-icon hide-mobile">
                <div className={`popover popover-description popover-${classEx}`}>
                  <p>{item.description}</p>
                </div>
                <Info className={`icon icon-attention icon-attention-${classEx}`}/>
              </div>
            </div>
          </td>
          <td className="cell-180 show-desktop">
            {this.renderDocuments(item.packageDocuments)}
          </td>
          <td className="cell-50 hide-mobile align-right">
            <div className="text-product">3</div>
          </td>
          <td className="cell-100 align-right">
            <div className="text-price">{formatPrice(item.priceWithVAT)}</div>
          </td>
        </tr>);
    });

    return list;
  }

    render() {
    const slaDate = calculateSLADate(this.props.selectedPackageIds, this.props.allPackages);

    return (
      <div className="content-block">
        <div className="content-block-title">
          <h2>Products Ordered</h2>
        </div>
        <div className="content-block-form">
          <table className="content-grid products-grid">
            <thead>
            <tr>
              <th>Product</th>
              <th className="cell-180 show-desktop">Documents</th>
              <th className="cell-50 hide-mobile align-right">Days</th>
              <th className="cell-100 align-right">Price <span>(Inc VAT)</span></th>
            </tr>
            </thead>
            <tbody>
            {this.renderProducts()}
            {/* If package is selected, use this instead:
             <tr>
             <td colSpan="4">
             <div className="cell-wrapper">
             <div className="text-heading">PACKAGE NAME</div>
             <div className="popover-icon hide-mobile">
             <div className="popover popover-description popover-package">
             <p>PACKAGE DESCRIPTION</p>
             </div>
             <Info className="icon icon-attention icon-attention-package"/>
             </div>
             </div>
             <table className="content-grid products-grid">
             <tbody>
             ... repeat L58-83 for every product in package
             </tbody>
             </table>
             </td>
             </tr>*/}

            </tbody>
          </table>
          <div className="order-wizard-summary">
            <div className="order-wizard-date">
              <strong>Estimated Completion</strong>
              <span className="date-badge">
                                    <span className="month">{slaDate.format('MMM')}</span>
                    <span className="day">{slaDate.date()}</span>
                                </span>
            </div>
            <PriceLines className='order-wizard-totals' vatRate={this.props.vatRate}
                        selectedPackageIds={this.props.selectedPackageIds}
                        selectedDiscountPackageCore={this.props.selectedDiscountPackageCore}
                        allPackages={this.props.allPackages}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  selectedPackageIds: selectSelectedPackages(),
  allPackages: selectAllPackages(),
  vatRate: selectVatRate(),
  selectedDiscountPackageCore: selectDiscountPackageCore(),

})

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(WizardProductOrdered);
