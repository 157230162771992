/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import * as actions from './actions';
import { createStructuredSelector } from 'reselect';
import * as queryActions from '../Queries/actions';
import { chaseOrder } from '../OrderDetails/actions';
import { uploadRequest } from '../../components/UploadFile/actions';
import {
    selectOrders,
    selectQuotes,
    selectOrdersCount,
    selectQuotesCount,
    selectQueryGridDataSource,
    selectQueriesCount,
    selectQueryGridNavigationColumnState,
    selectIsLoading,
    selectOrderGridRowState, selectIsTemplateNewOrderCreationPref
} from './selectors'
import { selectShowFilters, selectCustomerContacts } from '../App/selectors';
import { changeIsLoginPageVisible, loadCustomerContacts } from '../App/actions';
import PageTitle from './PageTitle';
import Filters from '../../components/Filters';
import GridQuotesDashboard from '../../components/GridQuotes/GridQuotesDashboard';
import GridOrdersDashboard from '../../components/GridOrders/GridOrdersDashboard';
import GridQueriesDashboard from '../../components/GridQueries/GridQueriesDashboard';
import DeleteQuoteDialog from '../Quotes/DeleteQuoteDialog';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

export class HomePage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.openDeleteQuoteConfirmationDialog = this.openDeleteQuoteConfirmationDialog.bind(this);
        this.closeDeleteQuoteConfirmationDialog = this.closeDeleteQuoteConfirmationDialog.bind(this);
        this.handleChase = this.handleChase.bind(this);

        this.state = {
            openDialog: false,
            quoteToDelete: 0,
            quoteToDeleteAddress: ''
        }
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    componentDidMount() {
        this.props.loadDashboard();
        this.props.loadCustomerContacts();
        this.props.changeIsLoginPageVisible(false);
        ReactGA.send({ hitType: "pageview", page: "/" });

    }

    executeSearch = (filterInfo) => {
        this.props.searchDashboard({
            dateCreated: filterInfo.dateCreated,
            filterText: filterInfo.filterText,
            createdBy: filterInfo.createdBy
        });
    }

    clearSearch = () => {
        this.props.loadDashboard();
    }

    getCustomerContactsFilterData() {
        const modifiedCustContacts = [];

        for (let i = 0; i < this.props.customerContacts.length; i++) {
            modifiedCustContacts.push({
                id: this.props.customerContacts[i].id,
                text: this.props.customerContacts[i].name
            });
        }

        return modifiedCustContacts;
    }

    openDeleteQuoteConfirmationDialog(quote) {
        const address = quote.address.line1;
        this.setState({ quoteToDeleteId: quote.id });
        this.setState({ quoteToDeleteAddress: address });
        this.setState({ openDialog: true });
    }

    closeDeleteQuoteConfirmationDialog() {
        this.setState({ openDialog: false });
    }

    handleChase(order) {
        const info = {
            orderId: order.orderId,
            dueDate: order.dueDate
        };

        this.props.chaseOrder(info);
    }

    render() {
        return (
            <React.Fragment>
                <PageTitle orderCteationType={this.props.runTemplate} />
                <Filters dropdownCreatedByDataSource={this.getCustomerContactsFilterData()}
                    showFilterProp={this.props.showFilterProp}
                    executeSearchAction={this.executeSearch}
                    filterClearedAction={this.clearSearch}
                />
                <GridQuotesDashboard
                    datasource={this.props.quotes}
                    itemsCount={this.props.quotesCount}
                    isLoading={this.props.isLoading}
                    openDeleteQuoteDialog={this.openDeleteQuoteConfirmationDialog} />
                <DeleteQuoteDialog isShown={this.state.openDialog}
                    handleCancel={this.closeDeleteQuoteConfirmationDialog}
                    quoteId={this.state.quoteToDeleteId}
                    quoteAddress={this.state.quoteToDeleteAddress} />
                <GridOrdersDashboard datasource={this.props.orders}
                    itemsCount={this.props.ordersCount}
                    isLoading={this.props.isLoading}
                    handleChase={this.handleChase}
                    orderIdChasing={this.props.orderIdChasing}
                    orderIdChasingMessage={this.props.orderIdChasingMessage}
                    orderGridRowState={this.props.orderGridRowState} />
                <GridQueriesDashboard isLoading={this.props.isLoading}
                    gridName='dashboardQueryGrid'
                    datasource={this.props.queries}
                    queriesCount={this.props.queriesCount}
                    showQueryReplyRow={this.props.loadQuery}
                    hideQueryReplyRow={this.props.hideQueryReplyRow}
                    addAttachmentToQuery={this.props.uploadRequest}
                    customerReplyToQuery={this.props.customerReplyToQuery}
                    customerReopenQuery={this.props.customerReopenQuery}
                    changeReplyText={this.props.changeReplyText}
                    queryGridNavigationColumnState={this.props.queryGridNavigationColumnState}
                    deleteQueryFile={this.props.deleteQueryFile}
                    showEarlierMsgs={this.props.showEarlierMsgs} />
            </React.Fragment>
        );
    }
}

HomePage.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
    ])
};

const mapStateToProps = createStructuredSelector({
    orders: selectOrders(),
    quotes: selectQuotes(),
    ordersCount: selectOrdersCount(),
    quotesCount: selectQuotesCount(),
    queries: selectQueryGridDataSource(),
    queriesCount: selectQueriesCount(),
    queryGridNavigationColumnState: selectQueryGridNavigationColumnState(),
    isLoading: selectIsLoading(),
    customerContacts: selectCustomerContacts(),
    orderGridRowState: selectOrderGridRowState(),
    showFilterProp: selectShowFilters(),
    runTemplate: selectIsTemplateNewOrderCreationPref()
});

const mapDispatchToProps = ({
    ...queryActions,
    ...actions,
    chaseOrder,
    uploadRequest,
    loadCustomerContacts: loadCustomerContacts,
    changeIsLoginPageVisible
});

var withSaga = injectSaga({ key: 'home', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/});
var withReducer = injectReducer({ key: 'home', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
  withSaga,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(HomePage);