import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { selectErrorMgs, selectShowForgottenPswdInitalForm, selectShowForgottenPswdError, selectActiveView, selectShowNotification, selectNotificationMsg } from './selectors';
import { selectLoading } from '../App/selectors';
import { remindMe, changeView, initPage, loginUser } from './actions';
import { changeIsLoginPageVisible } from '../App/actions';
import LoginForm from './login';
import ForgottenPassword from './forgottenPswd';
import Notification from './notifications';
import { useNavigate } from 'react-router-dom';
import rootSaga from './sagas';
import loginReducer from './reducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

const showErrorMsgSelector = selectErrorMgs();
const isLoadingSelector = selectLoading();
const showForgottenPswdInitalFormSelector = selectShowForgottenPswdInitalForm();
const showForgottenPswdErrorSelector = selectShowForgottenPswdError();
const activeViewSelector = selectActiveView();
const showNotificationSelector = selectShowNotification();
const notificationMsgSelector = selectNotificationMsg();

export default function Login(props) {
    useInjectReducer({ key: 'login', reducer: loginReducer });
    useInjectSaga({ key: 'login', saga: rootSaga/*, mode: RESTART_ON_REMOUNT*/ });
    let navigate = useNavigate();
    const showErrorMsg = useSelector(showErrorMsgSelector);
    const isLoading = useSelector(isLoadingSelector);
    const showForgottenPswdInitalForm = useSelector(showForgottenPswdInitalFormSelector);
    const showForgottenPswdError = useSelector(showForgottenPswdErrorSelector);
    const activeView = useSelector(activeViewSelector);
    const showNotification = useSelector(showNotificationSelector);
    const notificationMsg = useSelector(notificationMsgSelector);

    const dispatch = useDispatch();
    const loginUserAction = useCallback((userName, password, returnUrl, navigate) => dispatch(loginUser(userName, password, returnUrl, navigate)), [dispatch]);
    const changeViewAction = useCallback((view) => dispatch(changeView(view)), [dispatch]);
    const remindMeAction = useCallback((userName, email) => dispatch(remindMe(userName, email)), [dispatch]);
    const initPageAction = useCallback(() => dispatch(initPage()), [dispatch]);
    const changeIsLoginPageVisibleAction = useCallback((value) => dispatch(changeIsLoginPageVisible(value)), [dispatch]);

    useEffect(() => {
        changeIsLoginPageVisibleAction(true);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        initPageAction();
    }, []);


    if (activeView == 'login') {
        return (
            <React.Fragment>
                {showNotification == true ? <Notification message={notificationMsg} /> : null}
                <LoginForm
                    isLoading={isLoading}
                    showErrorMsg={showErrorMsg}
                    navigate={navigate}
                    //returnUrl = {location.query.returnUrl}
                    loginUserAction={loginUserAction}
                    changeViewAction={changeViewAction} />
            </React.Fragment>
        );
    }

    return <ForgottenPassword
        changeViewAction={changeViewAction}
        remindMeAction={remindMeAction}
        isLoading={isLoading}
        showForgottenPswdInitalForm={showForgottenPswdInitalForm}
        showForgottenPswdError={showForgottenPswdError} />;
}