import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectContactPerson, selectCustomerReference, selectHowIsAddressEntered, selectIsCreating } from './selectors';
import { selectContactPersonAction, selectCustomerReferenceAction, changeWizardStep, submitOrder, saveQuote } from './actions';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import NewOrderAddress from './newOrderAddress';
import NewOrderCustomer from './newOrderCustomer';
import Form from '../../components/Form';
import WizardProductOrdered from './WizardProductOrdered';
import {mapStateToPropsOS, createQuote, createOrder} from './newOrderSubmition';
import NewOrderAdditionalInfo from './NewOrderAdditionalInfo';

class WizardStep3 extends React.PureComponent{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        window.scrollTo(0,0);
        ReactGA.send({ hitType: "pageview", page: "/wizard3", title: "Custom Title" });
        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.event({
        category: 'Order wizard',
        action: 'start order wizard',
        label: 'step 3 for user: '+ userName
        });
    }

    handleSaveQuote(){
        var order = createQuote(this.props);
        this.props.saveQuoteAction({order: order});
    }

    handleSubmit(){
        if( this.props.contactPerson.id < 0 ||
            this.props.custReference.length < 1 ||
            this.props.howIsAddressEntered == 'none'){
            return;
        }

        var order = createOrder(this.props);
        this.props.submitOrderAction(order);
        ReactGA.send({ hitType: "pageview", page: "/create-order-wizard" });
        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.event({
            category: 'Order wizard',
            action: 'submit order from wizard',
            label: 'user: '+ userName
        });
    }

    render(){
        return(
             <Form className="content-wrapper">
                {/* Customer Information */}
                <NewOrderCustomer isExpandable={false} />

                {/* Property Information */}
                <NewOrderAddress  isExpandable={false} allowEdit={false}/>

                {/*Extra info*/}
                <NewOrderAdditionalInfo />

                {/* Products */}
                <WizardProductOrdered />

                {this.props.isCreating == true ? <div className='loader'></div> :
                <div className="order-wizard-actions actions-step-3">
                    <a onClick={()=>{this.props.changeWizardStep(2)}}  className="button button-regular button-white">Back: Products</a>
                    <button onClick={()=>{this.handleSaveQuote()}}  className="button button-regular button-white color-blue">Save Quote</button>
                    <button onClick={()=>{this.handleSubmit()}} type="submit" className="button button-regular button-green">Place Order</button>
                </div>
                }
                {/* <NewOrderSubmition ref={(el)=>{ this.submit = el; }}/> */}
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    contactPerson: selectContactPerson(),
    custReference: selectCustomerReference(),
    howIsAddressEntered: selectHowIsAddressEntered(),
    isCreating: selectIsCreating(),
    ...mapStateToPropsOS
})
const mapDispatchToProps={
    selectContactPerson: selectContactPersonAction,
    selectCustomerReference: selectCustomerReferenceAction,
    changeWizardStep : changeWizardStep,
    submitOrderAction: submitOrder,
    saveQuoteAction: saveQuote
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(WizardStep3);
