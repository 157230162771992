import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './actions';
import { loadCustomerContacts } from '../App/actions';
import { selectActiveCategoryTab, selectWizardStep, selectVisitedSteps } from './selectors';
import Address from '../../svg-icons/address';
import Cart from '../../svg-icons/cart';
import Check from '../../svg-icons/check';
import WizardStep1 from './wizard-step1';
import WizardStep2 from './wizard-step2';
import WizardStep3 from './wizard-step3';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';

class CreateOrderWizard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.initCreatePageState();
        this.props.loadPackagesInfo();
        this.props.loadCustomerContacts();
    }

    handleChangeStep(step) {
        if (this.props.visitedSteps.indexOf(step) == -1) {
            return;
        }

        this.props.changeWizardStep(step);
    }

    render() {
        return (
            <div>
                {/* Page Title */}
                <div className="page-title"> {/* Add className "fixed" when users scroll down and this reaches the top of the screen */}
                    <div className="content-wrapper">
                        <h1>{`New Order - Step ${this.props.wizardStep}/3`}</h1>
                        <ul className="page-title-menu hide-mobile">
                            <li className={this.props.wizardStep == 1 ? "active" : ''}>
                                <a onClick={() => { this.handleChangeStep(1) }} className="text-link">
                                    <Address className="icon icon-tab-address hide-mobile" />
                                    <span className="text">Property</span>
                                </a>
                            </li>
                            <li className={this.props.wizardStep == 2 ? "active" : ''}>
                                <a onClick={() => { this.handleChangeStep(2) }} className="text-link">
                                    <Cart className="icon icon-tab-product hide-mobile" />
                                    <span className="text">Products</span>
                                </a>
                            </li>
                            <li className={this.props.wizardStep == 3 ? "active" : ''}>
                                <a onClick={() => { this.handleChangeStep(3) }} className="text-link">
                                    <Check className="icon icon-tab-check hide-mobile" />
                                    <span className="text">Information</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Template */}
                {this.props.wizardStep == 1 ? <WizardStep1 /> : null}
                {this.props.wizardStep == 2 ? <WizardStep2 /> : null}
                {this.props.wizardStep == 3 ? <WizardStep3 /> : null}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    activeTab: selectActiveCategoryTab(),
    wizardStep: selectWizardStep(),
    visitedSteps: selectVisitedSteps()
})

const mapDispatchToProps = ({
    ...actions,
    loadCustomerContacts
})

var withSaga = injectSaga({ key: 'wizard', saga: homeSaga });
var withReducer = injectReducer({ key: 'create-order', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
    withSaga,
    withReducer,
    connect(mapStateToProps, mapDispatchToProps)
)(CreateOrderWizard);
