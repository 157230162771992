import { take, call, put, select, fork, cancel, takeLatest } from 'redux-saga/effects';
import { LOAD_ORDER_CONFIRMATION, LOAD_QUOTE_CONFIRMATION } from './constants';
import { loadOrderConfirmationLoaded, loadOrderConfirmationError } from './actions';
import config from 'utils/config';
import request from 'utils/request';

/**
 * Saga for order confirmation
 */
export function* loadConfirmOrderSagaRequest(action) {
  const requestURL = `${config.webApiUrl}/order/ConfirmOrder/${action.orderId}`;
  try {
    // Call our request helper (see 'utils/request')
    const order = yield call(request.getAuth, requestURL);
    yield put(loadOrderConfirmationLoaded(order));
  } 
  catch (err) {
    yield put(loadOrderConfirmationError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* loadConfirmOrderSaga() {
  yield takeLatest(LOAD_ORDER_CONFIRMATION, loadConfirmOrderSagaRequest);
}

/**
 * Saga for order confirmation
 */
export function* loadConfirmQuoteSagaRequest(action) {
  const requestURL = `${config.webApiUrl}/quote/ConfirmQuote/${action.quoteId}`;
  try {
    // Call our request helper (see 'utils/request')
    const order = yield call(request.getAuth, requestURL);
    yield put(loadOrderConfirmationLoaded(order));
  } 
  catch (err) {
    yield put(loadOrderConfirmationError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* loadConfirmQuoteSaga() {
  yield takeLatest(LOAD_QUOTE_CONFIRMATION, loadConfirmQuoteSagaRequest);
}

// Bootstrap sagas
export default function* rootSaga(){
  yield fork(loadConfirmOrderSaga);
  yield fork(loadConfirmQuoteSaga);
}
