import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectActiveCategoryTab, selectSelectedPackages, selectAllPackages, selectHowIsAddressEntered, selectAddress,
    selectDiscountPackageCore, selectSelectedPackagesInOrder, selectWarningPackage, selectShowSwitchPackageDlg,
    selectSwitchDlgSource } from './selectors';
import { changeWizardStep, selectPackage, changeProductCategoryTab, saveQuote, closeSwitchProductDlg, switchToSuggestedPackage } from './actions';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import NewOrderProducts from './newOrderProducts';
import Form from '../../components/Form';
import AsideWithCart from '../../components/Aside/asideWithCart';
import keys from 'lodash/keys';
import BasketContent from './basket/content';
import WizardAsideAddress from './WizardAsideAddress';
import { mapStateToPropsOS, createQuote } from './newOrderSubmition';
import SwitchProductDlg from './switchProductDlg';

class WizardStep2 extends React.PureComponent{
    constructor(props){
        super(props);
        this.handleSaveQuote = this.handleSaveQuote.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0,0);
        const userName = JSON.parse(sessionStorage.getItem('jwt')).user_name;
        ReactGA.send({ hitType: "pageview", page: "/create-order-wizard" });
        ReactGA.event({
        category: 'Order wizard',
        action: 'start order wizard',
        label: 'step 2 for user: '+ userName
        });
    }

    handleSaveQuote(){
        var order = createQuote(this.props);
        this.props.saveQuoteAction({order: order});
    }

    render(){
        return (
            <div>
             <Form className="content-wrapper details-wrapper">
                <section id="sectionWidth" className="details-main">
                    <NewOrderProducts changeTab={this.props.changeProductCategoryTab} activeTab={this.props.activeTab} selectPackage = {this.props.selectPackage}/>
                    <div className="order-wizard-actions actions-step-2">
                        <a onClick={()=>{this.props.changeWizardStep(1)}} className="button button-regular button-white">Back: Select Property</a>
                        {keys(this.props.selectedPackageIds).length > 0 ? <a onClick={()=>{this.props.changeWizardStep(3)}} className="button button-regular button-blue">Next: Information</a> : null}
                    </div>
                </section>
                <AsideWithCart>
                    <div className="details-aside-title">
                        <h4>Property Address</h4>
                    </div>
                    <WizardAsideAddress address={this.props.address} howIsAddressEntered={this.props.howIsAddressEntered} />
                    <div className="details-aside-title">
                        <h4>Basket ({keys(this.props.selectedPackageIds).length})</h4>
                    </div>
                    <BasketContent selectedPackageIds={this.props.selectedPackageIds}
                                    selectedPackagesInOrder={this.props.selectedPackagesInOrder}
                                    selectedDiscountPackageCore= {this.props.selectedDiscountPackageCore}
                                    allPackages={this.props.allPackages}
                                    deletePackage = {this.props.selectPackage}
                                    handleSaveQuote = {this.handleSaveQuote}
                                    isCreating={this.props.isCreating} showSubmit={false}
                                    warningPackages={this.props.warningPackages}/>
                </AsideWithCart>
                </Form>
                {this.props.showSwitchPackageDlg ?
                    <SwitchProductDlg
                        open={this.props.showSwitchPackageDlg}
                        maxWidth={600}
                        switchDlgSource={this.props.switchDlgSource}
                        switchToSuggestedPackage={this.props.switchToSuggestedPackage}
                        closeSwitchProductDlg={this.props.closeSwitchProductDlg}
                    />
                    : null}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    activeTab: selectActiveCategoryTab(),
    selectedPackageIds: selectSelectedPackages(),
    selectedDiscountPackageCore : selectDiscountPackageCore(),
    allPackages: selectAllPackages(),
    howIsAddressEntered: selectHowIsAddressEntered(),
    address: selectAddress(),
    selectedPackagesInOrder: selectSelectedPackagesInOrder(),
    warningPackages: selectWarningPackage(),
    showSwitchPackageDlg: selectShowSwitchPackageDlg(),
    switchDlgSource: selectSwitchDlgSource(),
    ...mapStateToPropsOS
})
const mapDispatchToProps={
    changeWizardStep: changeWizardStep,
    selectPackage : selectPackage,
    changeProductCategoryTab: changeProductCategoryTab,
    saveQuoteAction: saveQuote,
    closeSwitchProductDlg: closeSwitchProductDlg,
    switchToSuggestedPackage: switchToSuggestedPackage
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(WizardStep2);
