import { take, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import { LOAD_EXISTING_ORDER_FOR_COPY } from './constants';
import { loadQuoteDetailsSuccess, getAlertsFromLandmark } from './actions';
import { showAppError } from 'containers/App/actions';
import config from 'utils/config';
import request from 'utils/request';
import {loadPackagesSaleInfoRequest} from './sagas';

//
// API call to load order which will be copied
//
export function* loadCopyOrderRequest(action) {
  // console.log('loadCopyOrderRequest');
  var orderId = action.orderId || 0;
  var requestURL = `${config.webApiUrl}/order/getCopyOrder/${orderId}`;
  var requestType = request.getAuth;

  try {
    const quote = yield call(requestType, requestURL);
    yield loadPackagesSaleInfoRequest();
    yield put(loadQuoteDetailsSuccess(quote));
    yield put(getAlertsFromLandmark());
  }
  catch (err) {
    yield put(showAppError(err));
  }
}

export function* loadCopyOrderSaga() {
  yield takeLatest(LOAD_EXISTING_ORDER_FOR_COPY, loadCopyOrderRequest);
}

// Bootstrap sagas
export default function* rootSaga() {
  yield fork(loadCopyOrderSaga);
}
